import React, { useState } from 'react'
import { Blacked, ItemInput, ItemTable, ModalButtons, ModalDisplay, ModalHeader, ModalWrapper } from './ModalStyles';
import { message } from 'antd';
import { useToken } from 'app/hooks';
import API from 'utils/api';
import { useAuctionList } from '../hooks/GetAuctionList';


export const AuctionModalEdit = ({ setIsEdit, editItem }) => {
  const { data: token } = useToken();
  const { mutate } = useAuctionList();
  const [company, setCompany] = useState(editItem?.company);
  const [title, setTitle] = useState(editItem?.title);
  const [startedAt, setStartedAt] = useState(editItem?.startedAt);
  const [endedAt, setEndedAt] = useState(editItem?.endedAt);

  const saveEdit = () => {
    const shouldEdit = window.confirm(`수정하실 내용이 맞습니까?\n회사명: ${company}\n일정: ${title}\n시작일: ${startedAt}\n종료일: ${endedAt}`);
    if(shouldEdit) {
      API.put('/auction/schedule/list/' + editItem?.id, token + '', {
        datas: {
          company: company,
          type: 'string',
          title: title,
          round: 0,
          startedAt: startedAt,
          endedAt: endedAt,
        }
      })
      .then((res: any) => {
        if (res.result.success) {
          message.success('수정되었습니다');
          mutate();
          setIsEdit(false);
        }
      })
      .catch((err: any) => {})
    }
  }

  return (
    <ModalWrapper>
      <Blacked />
      <ModalDisplay style={{width: 1000}}>
        <ModalHeader>
          경매 수정
          <ModalButtons>
            <button className='cancel' onClick={() => setIsEdit(false)}>취소</button>
            <button className='save' onClick={saveEdit}>저장</button>
          </ModalButtons>
        </ModalHeader>
        <ItemTable>
          <tr>
            <th>회사명 *</th>
            <td><ItemInput type='text' value={company} onChange={(e) => setCompany(e.target.value)} placeholder='아티스트의 이름을 입력해주세요.(필수입력)' /></td>
          </tr>
          <tr>
            <th>일정</th>
            <td><ItemInput type='text' value={title} onChange={(e) => setTitle(e.target.value)} placeholder='아티스트의 출생연도를 입력해주세요.' /></td>
          </tr>
          <tr>
            <th>시작일</th>
            <td><ItemInput type='date' value={startedAt} onChange={(e) => setStartedAt(e.target.value)} placeholder='아티스트의 국적을 입력해주세요.' /></td>
          </tr>
          <tr>
            <th>종료일</th>
            <td><ItemInput type='date' value={endedAt} onChange={(e) => setEndedAt(e.target.value)} placeholder='아티스트의 정보가 담긴 사이트 링크를 입력해주세요.' /></td>
          </tr>
        </ItemTable>
      </ModalDisplay>
    </ModalWrapper>
  )
}

export default React.memo(AuctionModalEdit);