import React, { useState } from 'react'
import { Content, Header, Icon, SearchArea } from '../Main/styles';
import { ArtistListTable } from '../Artist/artistStyles';
import API from 'utils/api';
import { useToken } from 'app/hooks';
import { Input, Modal, message } from 'antd';
import { useAuctionSiteList } from 'app/Dosang/hooks/GetAuctionSiteList';
import AuctionSiteModalEdit from 'app/Dosang/Components/AuctionSiteModalEdit';
import { IoIosSearch } from "react-icons/io";
import { ModalWrapper } from 'app/Dosang/Components/ModalStyles';

export const AuctionSiteList = () => {
  const { auctionSiteList, mutate } = useAuctionSiteList('');
  const { data: token } = useToken();
  const [isEdit, setIsEdit] = useState(false);
  const [editItem, setIsEditItem] = useState({});
  const [search, setSearch] = useState('');
  const [preview, setPreview] = useState(false);
  const [previewImage, setPreviewImage] = useState('');
  const filterList = auctionSiteList?.filter((it) => it?.name.toLowerCase().includes(search.toLowerCase()));

  const clickDel = (id, name) => {
    const shouldDelete = window.confirm(`정말로 '${name}' 를 삭제하시겠습니까?`);

    if(shouldDelete) {
      API.delete('/auction/company/list/' + id, token + '', {})
      .then((res: any) => {
        if (res.result.success) {
          mutate()
          message.success('삭제되었습니다');
        }
      })
      .catch((err: any) => {})
    }
  }

  const columns = [
    {
      dataIndex: 'id',
      title: 'ID',
      render: (value, data) => {
        const id = data?.id;
        return (
          <div className='location'>
            <h5>{id}</h5>
          </div>
        )
      }
    },
    {
      dataIndex: 'name',
      title: '이름',
      render: (value, data) => {
        const name = data?.name;
        return (
          <div className='name'>
            <h5>{name}</h5>
          </div>
        )
      }
    },
    {
      dataIndex: 'link',
      title: '링크',
      render: (value, data) => {
        const link = data?.link;
        return (
          <div className='link'>
            <a href={link?.includes('https://') ? link : 'https://' + link} 
              target='_blank'
            >
              {link}
            </a>
          </div>
        )
      }
    },
    {
      dataIndex: 'link',
      title: '태그',
      render: (value, data) => {
        const tags = data?.tags;
        return (
          <div className='link' style={{display: 'flex', alignItems: 'center', gap: 6, flexWrap: 'wrap', maxWidth: 140}}>
            {tags?.map(it => (
              <span style={{fontSize: 14, fontWeight: 500, fontFamily: 'Pretendard', backgroundColor: '#4c4cf8', color: '#fff', padding: '4px', borderRadius: 4}}>{it}</span>
            ))}
          </div>
        )
      }
    },
    {
      dataIndex: 'link',
      title: '경매타입',
      render: (value, data) => {
        const popular = data?.popular;
        return (
          <div className='link'>
            {popular == 1 ? (
              <span style={{lineHeight: 1.5}}>인기<br />⭐</span>
            ) : (
              ''
            )}
          </div>
        )
      }
    },
    {
      dataIndex: 'endedAt',
      title: '심볼',
      render: (value, data) => {
        const image = data?.image;
        return (
          <div className='link'>
            {image ? (
                <img 
                  onClick={() => {setPreview(true); setPreviewImage(image)}} 
                  src={`https://api.dosang.io${image}`}
                  alt="symbol" 
                  style={{ width: '100px', height: 'auto', cursor: 'pointer' }} 
                />
            ) : null}
          </div>
        )
      }
    },
    {
      dataIndex: 'edit',
      title: '수정/삭제',
      render: (value, data) => {
        const id = data?.id;
        const name = data?.name;
        return (
          <div className='edit'>
            <button className='editBtn' onClick={() => {setIsEdit(true); setIsEditItem(data)}}>수정</button>
            <button className='delBtn' onClick={() => clickDel(id, name)}>삭제</button>
          </div>
        )
      }
    },
  ];

  return (
    <Content>
      <Header>
        경매 사이트 목록
        <SearchArea>
          <Input type='text' onChange={(e) => setSearch(e.target.value)} placeholder='사이트 이름 검색' />
          <Icon><IoIosSearch /></Icon>
        </SearchArea>
      </Header>
      <ArtistListTable dataSource={filterList} columns={columns} />
      {preview && (
        <Modal open={preview} footer={null} title='심볼 이미지' onCancel={() => setPreview(false)} style={{top: '25%', fontFamily: 'Pretendard'}}>
          <img alt="Symbol Image" style={{ width: '100%', marginTop: 20 }} src={process.env.REACT_APP_PUBLIC_URL + previewImage} />
        </Modal>
      )}
      {isEdit && <AuctionSiteModalEdit setIsEdit={setIsEdit} editItem={editItem} />}
    </Content>
  )
}

export default React.memo(AuctionSiteList);