import React, {useEffect, useState} from 'react'
import { Blacked, ItemInput, ItemTable, ModalButtons, ModalDisplay, ModalHeader, ModalWrapper } from './ModalStyles';
import {message, Table} from 'antd';
import { useToken } from 'app/hooks';
import API from 'utils/api';
import { useUserList } from '../hooks/GetUserList';
import { useUserSearchHistory} from "../hooks/GetUserSearchHistory";

export const UserSearchHistory = ({ selectId, setSelectId, setIsShowTotalSearch }) => {

  const {userSearchHistory} = useUserSearchHistory(selectId)


  // useEffect(() => {
  //   console.log('userSearchHistory', userSearchHistory)
  // }, [userSearchHistory]);


  const columns = [
    {
      dataIndex: 'id',
      title: 'ID',
      render: (value, data) => {
        return (
          <div className='title'>
            <h4>{value}</h4>
          </div>
        )
      }
    },
    {
      dataIndex: 'searchWord',
      title: '검색어',
      render: (value, data) => {
        return (
          <div className='content'>
            <h4>{value}</h4>
          </div>
        )
      }
    },
    {
      dataIndex: 'searchedAt',
      title: '검색일시',
      render: (value, data) => {
        const time = value.split('T')
        return (
          <div className='content'>
            <h4>{time[0] + ' ' + time[1].slice(0, 8)}</h4>
          </div>
        )
      }
    },
  ];



  return (
    <ModalWrapper>
      <Blacked />
      <ModalDisplay style={{width: 1000, overflowY: 'scroll', maxHeight: 700}}>
        <ModalHeader style={{marginBottom: 50}}>
         상세 검색 기록
          <ModalButtons>
            <button className='cancel' onClick={() => selectId ? setSelectId(null) : setIsShowTotalSearch(false)}>취소</button>
          </ModalButtons>
        </ModalHeader>

        <Table
          columns={columns}
          dataSource={userSearchHistory}
          pagination={{position: ['bottomCenter']}}
        />

      </ModalDisplay>
    </ModalWrapper>
  )
}

export default React.memo(UserSearchHistory);