import styled from "styled-components";

export const SideBarWrapper = styled.div`
  width: 200px;
  height: 100%;
  border-radius: 27px 0 0 27px;
  background-color: #e0e3e6;
  display: flex;
  flex-direction: column;
    position: relative;
`
export const Logo = styled.div`
  background-color: #191d21;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  padding: 0px 12px;
  border-right: 2px solid #e0e3e6;
  border-radius: 30px 0 0 0;
  .txtLogo {
    width: 60%;
  }
`
export const MenuWrapper = styled.div`
  display: flex;
  flex-direction: column;
  margin-top: 10px;
`
export const Menu = styled.div`
  position: relative;
  padding: 12px;
  color: #2c3037;
  font-weight: 700;
  cursor: pointer;
  &:hover {
    background-color: #2c3037;
    color: #fff;
  }
`
export const MenuDisplay = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 12px;
`
export const SubMenuWrapper = styled.div`
  position: absolute;
  right: -170px;
  top: 0;
  background-color: #e0e3e6;
  border-radius: 8px;
  display: none;
  width: 160px;
  z-index: 9;
  border: 2px solid #2c3037;
  ${Menu} {
    justify-content: center;
  }
`
export const SubHeader = styled.div`
  background-color: #2c3037;
  padding: 4px 12px;
  font-size: 12px;
  color: #e0e3e6;
  cursor: auto;
  user-select: none;
  border: 1px solid #e0e3e6;
  border-radius: 8px;
`