import React, { useState } from 'react'
import { Content, Header, Icon, LoadingTxt, LoadingWrapper, SearchArea } from '../../Main/styles';
import { useArtistList } from 'app/Dosang/hooks/GetArtistList';
import { Input, Spin, message } from 'antd';
import API from 'utils/api';
import { useToken } from 'app/hooks';
import AddArtistModal from 'app/Dosang/Components/ArtistModalEdit';
import ArtistModalEdit from 'app/Dosang/Components/ArtistModalEdit';
import { IoIosSearch } from "react-icons/io";
import { ArtistListTable } from '../../Artist/artistStyles';
import { useBoardList } from 'app/Dosang/hooks/GetBoard';
import NoticeEdit from 'app/Dosang/Components/NoticeEdit';
import InquireEdit from 'app/Dosang/Components/InquireEdit';

export const InquireList = () => {
  const { data: token } = useToken();
  const { boardList, mutate } = useBoardList('inquire');
  const [isEdit, setIsEdit] = useState(false);
  const [editItem, setIsEditItem] = useState({});
  const [search, setSearch] = useState('');
  const filterList = boardList?.filter((it) => it?.title.toLowerCase().includes(search.toLowerCase()) || it?.content?.toLowerCase().includes(search.toLowerCase()));

  const clickDel = (id) => {
    const shouldDelete = window.confirm(`정말로 문의를 삭제하시겠습니까?`);

    if(shouldDelete) {
      API.delete('/board/inquire' + '?postIds=' + id , token + '', {})
        .then((res: any) => {
          if (res.result.success) {
            message.success('삭제되었습니다');
            mutate();
          } else {
            message.error(res.result.message || '실패하였습니다.');
          }
        })
        .catch((err: any) => {
          message.error( '실패하였습니다.');
          console.log('문의 게시판 삭제 에러 =>>>',err)
        })
    }
  }

  const columns = [
    {
      dataIndex: 'name',
      title: '제목',
      render: (value, data) => {
        const title = data?.title;
        return (
          <div className='title'>
            <h5>{title}</h5>
          </div>
        )
      }
    },
    {
      dataIndex: 'birth',
      title: '내용',
      render: (value, data) => {
        const content = data?.content;
        return (
          <div className='content'>
            <h5>{content}</h5>
          </div>
        )
      }
    },
    {
      dataIndex: 'location',
      title: '등록일',
      render: (value, data) => {
        const rdate = data?.rdate;
        return (
          <div className='id'>
            <h5>{rdate}</h5>
          </div>
        )
      }
    },
    {
      dataIndex: '',
      title: '작성자',
      render: (value, data) => {
        const writer = data?.writer;
        return (
          <div className='location'>
            <h5>{writer}</h5>
          </div>
        )
      }
    },
    {
      dataIndex: 'edit',
      title: '답변',
      render: (value, data) => {
        return (
          <div className='edit'>
            {data?.answer == null || data?.answer == '' ? (
              <div style={{display: 'flex', gap: 5}}>
                <button className='editBtn' onClick={() => {
                  setIsEdit(true);
                  setIsEditItem(data)
                }}>작성
                </button>
                <button className='editBtn' onClick={() => {
                  clickDel(data?.id);
                }} style={{border: '1px solid #f53b3b', backgroundColor: 'transparent', color: '#f53b3b'}}>
                  문의 삭제
                </button>
              </div>
            ) : (
              <div style={{display: 'flex', gap: 5}}>
                <button className='editBtn' onClick={() => {
                  setIsEdit(true);
                  setIsEditItem(data)
                }} style={{border: '1px solid #f53b3b', backgroundColor: 'transparent', color: '#f53b3b'}}>
                  수정/삭제
                </button>

                <button className='editBtn' onClick={() => {
                  clickDel(data?.id);
                }} style={{border: '1px solid #f53b3b', backgroundColor: 'transparent', color: '#f53b3b'}}>
                  문의 삭제
                </button>
              </div>
            )}
          </div>
        )
      }
    },
  ];

  return (
    <Content>
      <Header>
        문의 목록
        <SearchArea>
          <Input type='text' onChange={(e) => setSearch(e.target.value)} placeholder='공지 제목 또는 내용 검색' />
          <Icon><IoIosSearch /></Icon>
        </SearchArea>
      </Header>
      <ArtistListTable dataSource={filterList} columns={columns} />
      {isEdit && <InquireEdit setIsEdit={setIsEdit} editItem={editItem} />}
    </Content>
  )
}

export default React.memo(InquireList);