import React from 'react'
import { fetcher } from 'app/hooks/fetcher'
import { Routes } from 'app/routes'
import { SWRConfig } from 'swr'
import ThemeProvider from 'theme/ThemeProvider'

const cacheProvider: any = cache => {
	const map = new Map(JSON.parse(localStorage.getItem('app-cache') || '[]'))

	window.addEventListener('beforeunload', () => {
		const appCache = JSON.stringify(Array.from(map.entries()))
		localStorage.setItem('app-cache', appCache)
	})
	window.addEventListener('pagehide', () => {
		const appCache = JSON.stringify(Array.from(map.entries()))
		localStorage.setItem('app-cache', appCache)
	})

	return map
}

const App = () => {
	return (
		<SWRConfig
			value={{
				fetcher: fetcher,
				provider: cacheProvider,
			}}>
			<ThemeProvider>
				<Routes />
			</ThemeProvider>
		</SWRConfig>
	)
}

export default App
