import React, { useEffect, useState } from 'react'
import {
	Blacked,
	CheckTag,
	ItemInput,
	ItemTable,
	ModalButtons,
	ModalDisplay,
	ModalHeader,
	ModalWrapper,
} from './ModalStyles'
import { Select, message, UploadFile, Modal, Upload, Input, Space, Tag, Checkbox } from 'antd'
import { useToken } from 'app/hooks'
import API from 'utils/api'
import { useAuctionSiteList } from '../hooks/GetAuctionSiteList'
import { PlusOutlined } from '@ant-design/icons'

type FileType = File

const getBase64 = (file: FileType): Promise<string> =>
	new Promise((resolve, reject) => {
		const reader = new FileReader()
		reader.readAsDataURL(file)
		reader.onload = () => resolve(reader.result as string)
		reader.onerror = error => reject(error)
	})

export const AuctionSiteModalEdit = ({ setIsEdit, editItem }) => {
	console.log(editItem)
	const { data: token } = useToken()
	const { auctionSiteList, mutate } = useAuctionSiteList('')
	const [name, setName] = useState(editItem?.name)
	const [link, setLink] = useState(editItem?.link)
	const [image, setImage] = useState(editItem?.image)
	const [deleteImage, setDeleteImage] = useState(false)

	const [previewOpen, setPreviewOpen] = useState(false)
	const [previewImage, setPreviewImage] = useState('')
	const [previewTitle, setPreviewTitle] = useState('')
	const [fileList, setFileList] = useState<UploadFile[]>([])

	const [type1, setType1] = useState('')
	const [type2, setType2] = useState('')
	const [type3, setType3] = useState('')
	const [type4, setType4] = useState('')
	const [type5, setType5] = useState('')
	const [popular, setPopular] = useState(false)

	console.log(editItem?.tags)

	useEffect(() => {
		if (editItem?.tags) {
			const tags = editItem.tags

			for (let i = 0; i < tags.length; i++) {
				if (tags[i] === '메이저') {
					setType1(tags[i])
				} else if (tags[i] === '프리미엄') {
					setType2(tags[i])
				} else if (tags[i] === '위클리') {
					setType3(tags[i])
				} else if (tags[i] === '온라인') {
					setType4(tags[i])
				} else if (tags[i] === '해외') {
					setType5(tags[i])
				}
			}
		}
	}, [editItem])

	const handleCancel = () => setPreviewOpen(false)

	const handlePreview = async (file: UploadFile) => {
		if (!file.url && !file.preview) {
			file.preview = await getBase64(file.originFileObj as FileType)
		}

		setPreviewImage(file.url || (file.preview as string))
		setPreviewOpen(true)
		setPreviewTitle(file.name || file.url!.substring(file.url!.lastIndexOf('/') + 1))
	}

	const handleChange = ({ fileList: newFileList }) => setFileList(newFileList)

	const uploadButton =
		fileList.length >= 1 ? null : (
			<div>
				<PlusOutlined />
				<div style={{ marginTop: 8 }}>Upload</div>
			</div>
		)

	const saveEdit = () => {
		const shouldEdit = window.confirm(`수정하실 내용이 맞습니까?\n사이트: ${name}\n링크: ${link}`)
		if (shouldEdit) {
			const targetFilesArray = Array.from(fileList)
			const formData = new FormData()

			targetFilesArray.forEach(file => {
				if (file.originFileObj) {
					formData.append('file', file.originFileObj)
				}
			})

			console.log(deleteImage)

			formData.append(
				'datas',
				JSON.stringify({
					name: name,
					link: link,
					popular: popular,
					status: true,
					deleteImage: deleteImage ? true : false,
					tags: [type1, type2, type3, type4, type5].filter(it => it !== ''),
				})
			)

			API.postImage('PUT', '/auction/company/list/' + editItem?.id, token + '', formData)
				.then((res: any) => {
					if (res.result.success) {
						message.success('수정되었습니다')
						mutate()
						setIsEdit(false)
					}
				})
				.catch((err: any) => {})
		}
	}

	const deleteSymbol = () => {
		message.success('심볼 제거 클릭')
		setDeleteImage(prev => !prev)
	}

	return (
		<ModalWrapper>
			<Blacked />
			<ModalDisplay style={{ width: 1000 }}>
				<ModalHeader>
					경매 사이트 수정
					<ModalButtons>
						<button className="cancel" onClick={() => setIsEdit(false)}>
							취소
						</button>
						<button className="save" onClick={saveEdit}>
							저장
						</button>
					</ModalButtons>
				</ModalHeader>
				<ItemTable>
					<tr>
						<th>이름 *</th>
						<td>
							<Space>
								<Input
									type="text"
									value={name}
									onChange={e => setName(e.target.value)}
									placeholder="수정하실 사이트의 이름을 입력해주세요."
								/>
								<Select value={name} onChange={e => setName(e)}>
									{auctionSiteList?.map((it, idx) => (
										<option key={idx} style={{ fontFamily: 'nanumSquareNeo' }}>
											{it.name}
										</option>
									))}
								</Select>
							</Space>
						</td>
					</tr>

					<tr>
						<th>인기경매</th>
						<td>
							<CheckTag style={{ padding: 0 }}>
								<Checkbox id="인기" checked={popular} onChange={() => setPopular(!popular)} />
								<label htmlFor="인기" style={{ color: popular ? '#101010' : '#a0a0a0' }}>
									인기 경매
								</label>
							</CheckTag>
						</td>
					</tr>
					<tr>
						<th>링크</th>
						<td>
							<ItemInput
								type="text"
								value={link}
								onChange={e => setLink(e.target.value)}
								placeholder="수정하실 사이트의 링크를 입력해주세요."
							/>
						</td>
					</tr>
					<tr>
						<th>
							심볼
							<br />
							(* 이미지 파일만 업로드 가능 / <br />
							새로 업로드하지 않아도 기존 이미지가 그대로 적용됩니다.)
						</th>
						<td>
							<>
								<Upload
									action="https://run.mocky.io/v3/435e224c-44fb-4773-9faf-380c5e6a2188"
									listType="picture-card"
									fileList={fileList}
									onPreview={handlePreview}
									onChange={handleChange}>
									{uploadButton}
								</Upload>
								<Modal
									open={previewOpen}
									title={previewTitle}
									footer={null}
									onCancel={handleCancel}>
									<img alt="example" style={{ width: '100%' }} src={previewImage} />
								</Modal>
							</>
						</td>
					</tr>

					<tr>
						<th>태그</th>
						<td>
							<Tag
								style={{ cursor: 'pointer' }}
								onClick={() => setType1(type1 ? '' : '메이저')}
								color={type1 ? 'blue' : 'default'}>
								메이저
							</Tag>
							<Tag
								style={{ cursor: 'pointer' }}
								onClick={() => setType2(type2 ? '' : '프리미엄')}
								color={type2 ? 'blue' : 'default'}>
								프리미엄
							</Tag>
							<Tag
								style={{ cursor: 'pointer' }}
								onClick={() => setType3(type3 ? '' : '위클리')}
								color={type3 ? 'blue' : 'default'}>
								위클리
							</Tag>
							<Tag
								style={{ cursor: 'pointer' }}
								onClick={() => setType4(type4 ? '' : '온라인')}
								color={type4 ? 'blue' : 'default'}>
								온라인
							</Tag>
							<Tag
								style={{ cursor: 'pointer' }}
								onClick={() => setType5(type5 ? '' : '해외')}
								color={type5 ? 'blue' : 'default'}>
								해외
							</Tag>
						</td>
					</tr>
					<tr>
						<th>심볼 제거</th>
						<td>
							<button
								onClick={deleteSymbol}
								style={{
									backgroundColor: '#dc3545',
									marginRight: 10,
									marginLeft: -0,
									border: '1px solid #dc3545',
								}}>
								심볼 이미지 제거 ({deleteImage ? '제거' : '유지'})
							</button>
							<span>심볼을 제거하시려면 해당 버튼 클릭 후 저장</span>
						</td>
					</tr>
				</ItemTable>
			</ModalDisplay>
		</ModalWrapper>
	)
}

export default React.memo(AuctionSiteModalEdit)
