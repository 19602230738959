import React, { useEffect, useRef, useState } from 'react'
import { AppDownBtn, AppDownBtns, HomeBackground, HomeDisplay, HomeTitle, HomeTitleImg, HomeTitleTxt, HomeWrapper, MobileAppDownBtn, PickerWrapper } from './styles';
import bgImg from '../../assets/bg.png'
import bgImgTablet from '../../assets/bg_tablet.png'
import bgImgMobile from '../../assets/bg_mobile.png'
import dosangLogo from '../../assets/dosangLogo.png'
import Picker from "react-scrollable-picker";
import { IoLogoAndroid } from "react-icons/io";
import { Icon } from '../Main/styles';
import { FaApple } from 'react-icons/fa6';
import Page1 from './Page/Page1';
import Page2 from './Page/Page2';
import Page3 from './Page/Page3';
import Page4 from './Page/Page4';
import Page5 from './Page/Page5';
import Page6 from './Page/Page6';
import {Footer} from "./Page/Footer";

export const Home = () => {
  const [range, setRange] = React.useState({ title: '실시간 응찰, 낙찰 알림 서비스' });
  const pickerRef = useRef(null);
  const [windowWidth, setWindowWidth] = useState(window.innerWidth);
  const [deviceType, setDeviceType] = useState('');

  useEffect(() => {
    const userAgent = navigator.userAgent || navigator.vendor;

    if (/android/i.test(userAgent)) {
      setDeviceType('Android');
    } else if (/iPad|iPhone|iPod/.test(userAgent)) {
      setDeviceType('iOS');
    }
  }, []);


  const textList = [
    '전 세계 미술 경매를 한눈에',
    '컬렉팅 최고의 도구',
    '실시간 응찰, 낙찰 알림 서비스',
    '655,000명의 작품 검색!',
    '누구나 쉽게 이용할 수 있는',
    '오픈 아트 플랫폼',
    '전 세계 미술 경매를 한눈에',
  ];
  const optionGroups = {
    title: textList.map((i) => ({ value: i, label: i }))
  };

  useEffect(() => {
    const handleResize = () => {
      setWindowWidth(window.innerWidth);
    };
    window.addEventListener('resize', handleResize);
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  useEffect(() => {
    const interval = setInterval(() => {
      const currentIndex = textList.findIndex(item => item === range.title);
      const nextIndex = (currentIndex + 1) % textList.length;
      handleChange('title', textList[nextIndex]);
    }, 2000);

    return () => clearInterval(interval);
  }, [range.title]);

  const handleChange = (name, value) => {
    const index = textList.findIndex((item) => item === value);
    setRange({
      ...range,
      [name]: value,
      [`${name}Index`]: index
    });
  };

  return (
    <>
      <HomeWrapper>
        <HomeBackground src={bgImg} />
        <HomeDisplay>
          <HomeTitle>
            <HomeTitleTxt>[ 도 : 상 ] 그림의 형상</HomeTitleTxt>
            <HomeTitleImg src={dosangLogo} />
          </HomeTitle>
          <PickerWrapper>
            <Picker
              optionGroups={optionGroups}
              valueGroups={range}
              onChange={handleChange}
              ref={pickerRef}
              itemHeight={40}
            />
          </PickerWrapper>
          <AppDownBtns>
            <AppDownBtn onClick={() => window.location.href = 'https://play.google.com/store/apps/details?id=com.ci.dosang&pcampaignid=web_share'}>
              <Icon>
                <IoLogoAndroid />
              </Icon>
              Android 다운로드
            </AppDownBtn>
            <AppDownBtn onClick={() => window.location.href = 'https://apps.apple.com/kr/app/%EB%8F%84%EC%83%81-dosang-%EB%AF%B8%EC%88%A0%ED%92%88-%EA%B2%BD%EB%A7%A4-%EC%95%84%ED%8A%B8-%EC%A0%95%EB%B3%B4/id6502923748'}>
              <Icon>
                <FaApple />
              </Icon>
              IOS 다운로드
            </AppDownBtn>
          </AppDownBtns>
        </HomeDisplay>
        <MobileAppDownBtn onClick={() => {
          if(deviceType === 'Android'){
            window.location.href = 'https://play.google.com/store/apps/details?id=com.ci.dosang&pcampaignid=web_share'
          } else {
            window.location.href = 'https://apps.apple.com/kr/app/%EB%8F%84%EC%83%81-dosang-%EB%AF%B8%EC%88%A0%ED%92%88-%EA%B2%BD%EB%A7%A4-%EC%95%84%ED%8A%B8-%EC%A0%95%EB%B3%B4/id6502923748'
          }
        }}>
          <HomeTitleImg src={dosangLogo} style={{width: 91, height: 14}} />
          <h6>다운로드</h6>
        </MobileAppDownBtn>
      </HomeWrapper>
      <Page1 />
      <Page2 />
      <Page3 />
      <Page4 />
      <Page5 />
      <Page6 />
      <Footer/>
    </>
  )
}

export default React.memo(Home);