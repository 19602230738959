import React, { useState } from 'react'
import {
	Blacked,
	ItemInput,
	ItemTable,
	ModalButtons,
	ModalDisplay,
	ModalHeader,
	ModalWrapper,
} from './ModalStyles'
import { Modal, Upload, UploadFile, message } from 'antd'
import { useToken } from 'app/hooks'
import API from 'utils/api'
import { useArtistList } from '../hooks/GetArtistList'
import { useAuctionSiteList } from '../hooks/GetAuctionSiteList'
import { PlusOutlined } from '@ant-design/icons'

type FileType = File

const imagepath = `https://api.dosang.io/public/uploadImage/`

const getBase64 = (file: FileType): Promise<string> =>
	new Promise((resolve, reject) => {
		const reader = new FileReader()
		reader.readAsDataURL(file)
		reader.onload = () => resolve(reader.result as string)
		reader.onerror = error => reject(error)
	})
export const ArtistModalEdit = ({ setIsEdit, editItem }) => {
	const { data: token } = useToken()
	const { mutate } = useArtistList()
	const [name, setName] = useState(editItem?.name)
	const [orName, setOrName] = useState(editItem?.orName)
	const [birth, setBirth] = useState(editItem?.birth)
	const [location, setLocation] = useState(editItem?.location)
	const [link, setLink] = useState(editItem?.link)
	const [imageUrl, setImageLink] = useState(editItem?.imageUrl || '')
	//파일업로드관련
	const [deleteImage, setDeleteImage] = useState(false)

	const [previewOpen, setPreviewOpen] = useState(false)
	const [previewImage, setPreviewImage] = useState('')
	const [previewTitle, setPreviewTitle] = useState('')
	const [fileList, setFileList] = useState<UploadFile[]>([])

	const saveEdit = () => {
		const shouldEdit = window.confirm(
			`수정하실 내용이 맞습니까?\n이름: ${name}\n출생: ${birth}\n국적: ${location}\n아티스트 정보 링크: ${link}\n아티스트 이미지 링크: ${imageUrl} `
		)

		const targetFilesArray = Array.from(fileList)
		const formData = new FormData()

		targetFilesArray.forEach(file => {
			if (file.originFileObj) {
				formData.append('file', file.originFileObj)
			}
		})

		formData.append(
			'datas',
			JSON.stringify({
				uniqueId: editItem?.id,
				koName: orName,
				name: name,
				birth: birth,
				imageUrl: imageUrl,
				link: link,
				location: location,
				deleteImage: deleteImage,
			})
		)

		if (shouldEdit) {
			API.postImage('PUT', '/artist/list/' + editItem?.id, token + '', formData)
				.then((res: any) => {
					if (res.result.success) {
						message.success('수정되었습니다')
						mutate()
						setIsEdit(false)
					}
				})
				.catch((err: any) => {})
		}
	}

	const handleCancel = () => setPreviewOpen(false)

	const handlePreview = async (file: UploadFile) => {
		if (!file.url && !file.preview) {
			file.preview = await getBase64(file.originFileObj as FileType)
		}

		setPreviewImage(file.url || (file.preview as string))
		setPreviewOpen(true)
		setPreviewTitle(file.name || file.url!.substring(file.url!.lastIndexOf('/') + 1))
	}

	const uploadButton =
		fileList.length >= 1 ? null : (
			<div>
				<PlusOutlined />
				<div style={{ marginTop: 8 }}>Upload</div>
			</div>
		)

	const handleChange = ({ fileList: newFileList }) => setFileList(newFileList)
	return (
		<ModalWrapper>
			<Blacked />
			<ModalDisplay style={{ width: 1000 }}>
				<ModalHeader>
					아티스트 수정
					<ModalButtons>
						<button className="cancel" onClick={() => setIsEdit(false)}>
							취소
						</button>
						<button className="save" onClick={saveEdit}>
							저장
						</button>
					</ModalButtons>
				</ModalHeader>
				<ItemTable>
					<tr>
						<th>이름 *</th>
						<td>
							<ItemInput type="text" value={name} onChange={e => setName(e.target.value)} />
						</td>
					</tr>
					<tr>
						<th>
							다른 이름
							<br />
							(영문이나 한글로 바꾼 이름)
						</th>
						<td>
							<ItemInput type="text" value={orName} onChange={e => setOrName(e.target.value)} />
						</td>
					</tr>
					<tr>
						<th>출생</th>
						<td>
							<ItemInput type="text" value={birth} onChange={e => setBirth(e.target.value)} />
						</td>
					</tr>
					<tr>
						<th>국적</th>
						<td>
							<ItemInput type="text" value={location} onChange={e => setLocation(e.target.value)} />
						</td>
					</tr>
					<tr>
						<th>아티스트 정보 링크</th>
						<td>
							<ItemInput type="text" value={link} onChange={e => setLink(e.target.value)} />
						</td>
					</tr>
					<tr>
						<th>아티스트 이미지</th>
						<td>
							<>
								{imageUrl && (
									<>
										<img
											src={`${imageUrl?.includes('http') ? imageUrl : imagepath + imageUrl}`}
											alt="artist"
											style={{ width: '50px' }}
										/>

										<button
											onClick={() => {
												setDeleteImage(true)
											}}
											style={{
												backgroundColor: '#dc3545',
												marginRight: 10,
												marginLeft: -0,
												border: '1px solid #dc3545',
											}}>
											이미지 제거
										</button>
									</>
								)}
								<Upload
									action="https://run.mocky.io/v3/435e224c-44fb-4773-9faf-380c5e6a2188"
									listType="picture-card"
									fileList={fileList}
									onPreview={handlePreview}
									onChange={handleChange}>
									{uploadButton}
								</Upload>
								<Modal
									open={previewOpen}
									title={previewTitle}
									footer={null}
									onCancel={handleCancel}>
									<img alt="example" style={{ width: '100%' }} src={previewImage} />
								</Modal>
							</>
						</td>
					</tr>
				</ItemTable>
			</ModalDisplay>
		</ModalWrapper>
	)
}

export default React.memo(ArtistModalEdit)
