import useSWR from 'swr'
import API from '../../../utils/api'
import { getPersistData } from '../../hooks/persist'
import { useToken } from 'app/hooks'

const KEY = '/board/newsfeed' // (8) 뉴스피드 조회

let swrData: any = getPersistData(KEY)

export function useNewsFeed() {
	const { data: token } = useToken()
	const { data, mutate } = useSWR<any>(
		KEY,
		async () => {
			const response = await API.get(KEY, token + '', {})
			swrData = response.result?.result

			return swrData
		},
		{ revalidateOnFocus: true }
	)

	return {
		newsFeedData: data,
		mutate: (value?: any) => {
			if (value != undefined) {
				swrData = value
			}
			return mutate()
		},
	}
}