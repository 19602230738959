import React, { useEffect, useState } from 'react'
import { GlowEffect, HomeWrapper, Page1Display, Page1ImgWrapper, Page3Txt, Page3TxtArea, PageImg, PageSubhead, PageTitleArea, PageTitleTxt } from '../styles';
import Page3Img from '../../../assets/page3.png'

const useWindowWidth = () => {
  const [width, setWidth] = useState(window.innerWidth);

  useEffect(() => {
    const handleResize = () => {
      setWidth(window.innerWidth);
    };

    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  return width;
};

export const Page3 = () => {
  const width = useWindowWidth();

  return (
    <HomeWrapper style={{backgroundColor: '#030303', height: 'auto', paddingTop: 0, marginTop: width >= 1200 ? 0 : width >= 800 ? -500 : -500 }}>
      <Page1Display>
        <PageTitleArea>
          <PageTitleTxt>[ 편리한 작가 검색 ]</PageTitleTxt>
          <PageSubhead>
            사용자 경험을 고려한<br /> 최적화된 작가 검색.
          </PageSubhead>
        </PageTitleArea>
        <Page1ImgWrapper style={{margin: '0 50px'}}>
          {/* <GlowEffect /> */}
          <PageImg src={Page3Img} />
        </Page1ImgWrapper>
        <Page3TxtArea>
          <Page3Txt>최근 검색기록</Page3Txt>
          <Page3Txt>검색어 자동완성</Page3Txt>
          <Page3Txt>검색작가 즐겨찾기</Page3Txt>
        </Page3TxtArea>
      </Page1Display>
    </HomeWrapper>
  )
}

export default React.memo(Page3);