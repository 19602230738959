import useSWR from 'swr'
import API from '../../../utils/api'
import { getPersistData } from '../../hooks/persist'
import { useToken } from 'app/hooks'

const KEY = '/board/' // (1) 게시글 목록 조회

let swrData: any = getPersistData(KEY)

export function useBoardList(boardName) {
	const { data: token } = useToken()
	const { data, mutate } = useSWR<any>(
		KEY + boardName + '?page=1&maxData=999999999',
		async () => {
			const response = await API.get(KEY + boardName + '?page=1&maxData=999999999', token + '', {})
			swrData = response.result?.result?.list

			return swrData
		},
		{ revalidateOnFocus: true }
	)

	return {
		boardList: data,
		mutate: (value?: any) => {
			if (value != undefined) {
				swrData = value
			}
			return mutate()
		},
	}
}