import React, { useState } from 'react'
import { Blacked, ItemInput, ItemTable, ModalButtons, ModalDisplay, ModalHeader, ModalWrapper } from './ModalStyles';
import { message } from 'antd';
import { useToken } from 'app/hooks';
import API from 'utils/api';
import TextArea from 'antd/es/input/TextArea';
import { useBoardList } from '../hooks/GetBoard';

export const NoticeEdit = ({ setIsEdit, editItem }) => {
  const { data: token } = useToken();
  const { mutate } = useBoardList('notice');
  const [title, setTitle] = useState(editItem?.title);
  const [content, setContent] = useState(editItem?.content);

  const saveEdit = () => {
    const shouldEdit = window.confirm(`수정하실 내용이 맞습니까?\n제목: ${title}\n내용: ${content}`);
    if (title == '') {
      message.error('제목은 필수 입력입니다.');
    } else {
      if(shouldEdit) {
        API.put('/board/notice/' + editItem?.id, token + '', {
          datas: {
            title: title,
            content: content,
            link: null,
            pubdate: null,
            status: true
          }
        })
        .then((res: any) => {
          if (res.result.success) {
            message.success('수정되었습니다');
            mutate();
            setIsEdit(false);
          }
        })
        .catch((err: any) => {})
      }
    }
  }

  return (
    <ModalWrapper>
      <Blacked />
      <ModalDisplay style={{width: 1000, height: 'auto'}}>
        <ModalHeader>
          공지 수정
          <ModalButtons>
            <button className='cancel' onClick={() => setIsEdit(false)}>취소</button>
            <button className='save' onClick={saveEdit}>저장</button>
          </ModalButtons>
        </ModalHeader>
        <ItemTable>
          <tr>
            <th>제목 *</th>
            <td><ItemInput type='text' value={title} onChange={(e) => setTitle(e.target.value)} placeholder='제목을 입력해주세요.(필수입력)' /></td>
          </tr>
          <tr>
            <th>내용</th>
            <td>
              <div style={{height: 300}}>
                <TextArea value={content} onChange={(e) => setContent(e.target.value)} placeholder='내용을 입력해주세요.'  style={{minHeight: 280, resize: 'none'}} />
              </div>
            </td>
          </tr>
        </ItemTable>
      </ModalDisplay>
    </ModalWrapper>
  )
}

export default React.memo(NoticeEdit);