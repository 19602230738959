import React, { useState } from 'react'
import { Content, Header } from '../Main/styles';
import { ArtistListTable } from './artistStyles';
import { useArtistTrendingList } from 'app/Dosang/hooks/GetArtistTrending';
import { FaCrown, FaMedal } from 'react-icons/fa6';
import { View } from 'styles/reactnative';
import ArtistRankEditModal from 'app/Dosang/Components/ArtistRankEditModal';
import styled from "styled-components";

export const ArtistRank = () => {
  const { artistTrendingList } = useArtistTrendingList();
  const List = artistTrendingList?.slice(0, 15);
  const [isEdit, setIsEdit] = useState(false);
  const [editItem, setIsEditItem] = useState({});

  const columns = [
    {
      dataIndex: 'rank',
      title: '순위',
      render: (value, data) => {
        const rank = data?.rank;
        return (
          <div className='rank'>
            <h5 style={{color: rank == 1 ? '#FFD700' : rank == 2 ? ' #C0C0C0' : rank == 3 ? '#CD7F32' : '', fontWeight: 900 }}>
              {rank == 1 ? (
                <View>
                  <FaCrown />
                  {rank}
                </View>
              ) : rank}
            </h5>
          </div>
        )
      }
    },
    {
      dataIndex: 'name',
      title: '이름',
      render: (value, data) => {
        const name = data?.name;
        const orName = data?.koName;
        return (
          <div className='name'>
            <h5>{name}</h5>
            <h5>{orName}</h5>
          </div>
        )
      }
    },
    {
      dataIndex: 'birth',
      title: '출생',
      render: (value, data) => {
        const birth = data?.birth;

        if (birth == 'CIRCA 1900') {
          return '약 1900년경'
        }
        return (
          <div className='birth'>
            {birth !== '19TH CENTURY' && birth !== '20TH CENTURY' ? (
              <>
                <h5>{birth !== null ? birth : '?' }</h5>
              </>
            ) : (
              birth == '19TH CENTURY' ? <h5>19세기</h5> : <h5>20세기</h5>
            ) }
          </div>
        )
      }
    },
    {
      dataIndex: 'location',
      title: '국적',
      render: (value, data) => {
        const location = data?.location;
        return (
          <div className='location'>
            <h5>{location}</h5>
          </div>
        )
      }
    },
    {
      dataIndex: 'link',
      title: '아티스트 정보',
      render: (value, data) => {
        let link = data?.link

        // if (link && !link.endsWith('/Artworks') && !link.endsWith('/artworks')) {
        //   link += '/Artworks';
        // }

        return (
          <div className='link'>
            <a href={link} target='_blank'>{link}</a>
          </div>
        )
      }
    },
    {
      dataIndex: 'edit',
      title: '수정',
      render: (value, data) => {
        const id = data?.id;
        const name = data?.name;
        return (
          <div className='edit'>
            <button className='editBtn' onClick={() => {setIsEdit(true); setIsEditItem(data)}}>수정</button>
          </div>
        )
      }
    },
  ];

  return (
    <Content>
      <Header>아티스트 실시간 순위
        <AddArtist onClick={() => setIsEdit(true)}>수동등록</AddArtist>
      </Header>
      <ArtistListTable dataSource={List} columns={columns} pagination={{pageSize: 15}} />
      {isEdit && <ArtistRankEditModal setIsEdit={setIsEdit} editItem={editItem} setIsEditItem={setIsEditItem} />}
    </Content>
  )
}

export default React.memo(ArtistRank);

const AddArtist = styled.div`
  background-color: #0e669a;
  border-radius: 10px;
  font-size: 14px;
  padding: 10px 15px;
  cursor: pointer;
`
