import { Table } from "antd";
import styled from "styled-components";

// ** ArtistList.tsx =======
export const ArtistListTable = styled(Table)`
  width: 100%;
  margin-top: 20px;
  .ant-table-content {
    max-height: 700px;
    overflow-y: auto;
    border: none;
  }
  .ant-pagination {
    position: absolute;
    width: 100%;
    justify-content: center;
    background-color: #e0e3e6;
    margin: 0px !important;
    border-radius: 0px 0px 8px 8px;
    padding: 12px;
    .ant-pagination-item-active {
      background-color: #2c3037;
      color: #e0e3e6;
      border-color: #e0e3e6;
      & > a {
        color: #e0e3e6;
      }
    }
    > * {
      font-family: 'nanumSquareNeo';
    }
    .ant-select-selection-item {
      font-family: 'nanumSquareNeo';
      font-size: 14px;
    }
  }
  .ant-table-cell {
    font-family: 'nanumSquareNeo';
    font-size: 16px;
    border: 1px solid #2c3037;
    vertical-align: middle;
    &:hover {
      border: 1px solid #e0e3e6;
    }
  }
  .ant-table-thead > tr > th {
    text-align: center;
    border-bottom: 1px solid #2c3037;
  }
  .ant-table-cell-row-hover {
    background-color: #2c3037 !important;
    color: #e0e3e6;
  }
  .birth, .id, .location, .rank, .link {
    text-align: center;
  }
  .title, .content {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    max-width: 200px;
  }
  .title {
    max-width: 150px;
  }
  .link {
    width: 100%;
    white-space: pre-wrap;
    line-height: 1 !important;
    display: flex;
    align-items: center;
    justify-content: center;
    a {
      max-width: 180px;
      font-size: 12px;
      font-family: Pretendard;
      font-weight: 700;
      text-decoration: underline;
    }
  }
  .edit {
    min-width: 100px;
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 10px;
    button {
      border: none;
      background-color: transparent;
      border: 1px solid #2c3037;
      border-radius: 4px;
      padding: 4px 6px;
      font-family: 'nanumSquareNeo';
    }
    .editBtn {
      background-color: #e0e3e6;
      color: #2c3037;
      border: 1px solid #e0e3e6;
    }
    .delBtn {
      background-color: #e92323;
      color: #fff;
      border: 1px solid red;
    }
  }
`
export const ImgPreviewWrapper = styled.div`
  width: 150px;
  height: 150px;
  border: 3px solid #2c3037;
  z-index: 99;
  display: flex;
  align-items: center;
  justify-content: center;
  position: absolute;
  bottom: 200px;
  left: 380px;
  > img {
    width: 100%;
    height: 100%;
    object-fit: contain;
  }
`