import React from 'react'
import styled from "styled-components";

export const Footer = () => {
  return(
    <FooterComponent>
      <TopWrapper>
        <LeftSide>
          {`주식회사 오픈아트  대표 김근도 
        
사업자등록번호 : 154 - 81 - 03190 

주소 : 서울시 서초구 강남대로 53길 8. 7-122호 

스타크 강남빌딩 
`}
        </LeftSide>

        <RightSide>
          {`전화 : 1688-5610 
        
이메일 : info@openart.kr 
`}
        </RightSide>
      </TopWrapper>

      <CopyRight>Copyright 2023 주식회사 오픈아트. All Rights Reserved </CopyRight>


    </FooterComponent>
  )
}

const FooterComponent = styled.footer`
  width: 100%;
  height: 300px;
  background-color: #101010;
  padding: 60px 100px;
  gap: 50px;
  
  @media (max-width: 767px) {
    height: 100%;
    padding: 30px 20px;
  }
`

const TopWrapper = styled.div`
  display: flex;
  gap: 100px;

  @media (max-width: 767px) {
    display: block;
  }
`

const LeftSide = styled.div`
  white-space: pre-wrap;
  color: white;
  font-size: 16px;

    @media screen and (max-width: 1200px) {
        font-size: 3.3vw;
    }
`

const RightSide = styled.div`
  white-space: pre-wrap;
  color: white;
  font-size: 16px;

    @media screen and (max-width: 1200px) {
        font-size: 3.3vw;
    }
`


const CopyRight = styled.div`
  color: white;
  font-size: 14px;
  margin-top: 40px;
  width: 100%;
  display: flex;
  justify-content: flex-end;

  @media (max-width: 767px) {
    justify-content: flex-start;
      font-size: 3vw;
  }
`