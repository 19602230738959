import useSWR from 'swr'
import API from '../../../utils/api'
import { getPersistData } from '../../hooks/persist'
import { useToken } from 'app/hooks'

const KEY = '/artist/list' // (1) 전체 아티스트 목록 조회

let swrData: any = getPersistData(KEY)

const fetchKey = (page, pageSize, search) =>
	`/artist/list?page=${page}&maxData=${pageSize}&searchWord=${search}`;

export function useArtistList(page = 1, pageSize = 10, search='') {
	const { data: token } = useToken()
	const url = fetchKey(page, pageSize, search);

	const { data, mutate } = useSWR<any>(
		url,
		async () => {
			const response = await API.get(url, token + '', {})
			swrData = response.result?.result

			return swrData
		},
		{ revalidateOnFocus: true }
	)

	return {
		artistList: data?.list,
		total:data?.totalCount,
		mutate: (value?: any) => {
			if (value != undefined) {
				swrData = value
			}
			return mutate()
		},
	}
}