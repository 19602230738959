import { Input } from "antd";
import styled from "styled-components";

export const Blacked = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  background-color: rgba(0, 0, 0, 0.5);
  width: 100%;
  height: 100vh;
`
export const ModalWrapper = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #191d21;
`
export const ModalDisplay = styled.div`
  background-color: #fff;
  width: 100%;
  min-height: 500px;
  height: auto;
  z-index: 11;
  border-radius: 8px;
  padding: 40px;
`
export const ModalHeader = styled.div`
  width: 100%;
  position: relative;
  font-size: 22px;
  color: #191d21;
  font-weight: 700;
  display: flex;
  align-items: center;
  justify-content: center;
`
export const ModalButtons = styled.div`
  position: absolute;
  right: 0;
  top: 0;
  display: flex;
  align-items: center;
  gap: 8px;
  button {
    background-color: transparent;
    font-family: 'nanumSquareNeo';
    padding: 8px 12px;
    border-radius: 4px;
  }
  .cancel {
    border: 1px solid #f53b3b;
    color: #f53b3b;
    transition: all.3s;
    &:hover {
      background-color: #f53b3b;
      color: #fff;
    }
  }
  .save {
    border: 1px solid #4c4cf8;
    color: #fff;
    background-color: #4c4cf8;
    transition: all.3s;
  }
`
export const ItemTable = styled.table`
  margin-top: 70px;
  border: 1px solid #191d21;
  width: 100%;
  th {
    border-bottom: 1px solid #191d21;
    padding: 16px 12px;
    min-width: 184px;
    vertical-align: middle;
  }
  td {
    width: 70%;
    min-height: 61px;
    border-bottom: 1px solid #191d21;
    padding: 8px;
    vertical-align: middle;
    button {
      border: 1px solid #4c4cf8;
      color: #fff;
      background-color: #4c4cf8;
      transition: all.3s;
      font-family: 'nanumSquareNeo';
      padding: 12px 30px;
      border-radius: 4px;
      margin-left: 20px;
    }
    .ant-select-selector {
      font-family: 'nanumSquareNeo';
    }
  }
`
export const ItemInput = styled(Input)`
  font-family: 'nanumSquareNeo';
  height: 100%;
  padding: 12px;
  /* border: none; */
`
export const CheckTagDiv = styled.div`
  display: flex;
  align-items: center;
  gap: 8px;
`
export const CheckTag = styled.div`
  display: flex;
  align-items: center;
  gap: 8px;
  color: #a0a0a0;
  padding: 4px 8px;
  border-radius: 4px;
  background-color: #fff;
  cursor: pointer;
  label {
    user-select: none;
    cursor: pointer;
  }
`