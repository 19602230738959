import React, { useState } from 'react'
import { Content, Header } from '../Main/styles'
import {
	ItemInput,
	ItemTable,
	ModalButtons,
	ModalDisplay,
	ModalHeader,
	ModalWrapper,
} from '../../Components/ModalStyles'
import { Modal, Upload, UploadFile, message } from 'antd'
import { useToken } from 'app/hooks'
import API from 'utils/api'
import { useArtistList } from 'app/Dosang/hooks/GetArtistList'
import { useHistory } from 'react-router-dom'

import { PlusOutlined } from '@ant-design/icons'
type FileType = File

const getBase64 = (file: FileType): Promise<string> =>
	new Promise((resolve, reject) => {
		const reader = new FileReader()
		reader.readAsDataURL(file)
		reader.onload = () => resolve(reader.result as string)
		reader.onerror = error => reject(error)
	})
export const ArtistAdd = () => {
	const { data: token } = useToken()
	const { mutate } = useArtistList()
	const [id, setId] = useState('')
	const [name, setName] = useState('')
	const [koName, setKoName] = useState('')
	const [birth, setBirth] = useState('')
	const [location, setLocation] = useState('')
	const [link, setLink] = useState('')
	const [imageUrl, setImageLink] = useState('')
	const navigate = useHistory()

	const [previewOpen, setPreviewOpen] = useState(false)
	const [previewImage, setPreviewImage] = useState('')
	const [previewTitle, setPreviewTitle] = useState('')
	const [fileList, setFileList] = useState<UploadFile[]>([])

	const saveEdit = () => {
		const shouldEdit = window.confirm(
			`등록하실 내용이 맞습니까?\n아이디: ${id}\n이름: ${name}\n출생: ${birth}\n국적: ${location}\n아티스트 정보 링크: ${link}\n아티스트 이미지 링크: ${imageUrl} `
		)

		const targetFilesArray = Array.from(fileList)
		const formData = new FormData()

		targetFilesArray.forEach(file => {
			if (file.originFileObj) {
				formData.append('file', file.originFileObj)
			}
		})

		if (shouldEdit) {
			if (id == '' && name == '' && birth == '' && location == '' && link == '' && imageUrl == '') {
				message.error('등록하실 아티스트 정보를 입력해주세요.')
			} else if (name == '') {
				message.error('이름은 필수 입력입니다.')
			} else {
				formData.append(
					'datas',
					JSON.stringify({
						uniqueId: id,
						koName,
						name: name,
						birth: birth,
						imageUrl: imageUrl,
						link: link,
						location: location,
					})
				)

				API.postImage('POST', '/artist/list', token + '', formData)
					.then((res: any) => {
						if (res.result.success) {
							message.success('등록되었습니다')
							mutate()
							navigate.push('/dsadmin/artist/list')
						}
					})
					.catch((err: any) => {})
			}
		}
	}

	const handleCancel = () => setPreviewOpen(false)

	const handlePreview = async (file: UploadFile) => {
		if (!file.url && !file.preview) {
			file.preview = await getBase64(file.originFileObj as FileType)
		}

		setPreviewImage(file.url || (file.preview as string))
		setPreviewOpen(true)
		setPreviewTitle(file.name || file.url!.substring(file.url!.lastIndexOf('/') + 1))
	}

	const handleChange = ({ fileList: newFileList }) => setFileList(newFileList)
	const uploadButton =
		fileList.length >= 1 ? null : (
			<div>
				<PlusOutlined />
				<div style={{ marginTop: 8 }}>Upload</div>
			</div>
		)

	return (
		<Content style={{ position: 'relative' }}>
			<Header>아티스트 수동 등록</Header>
			<ModalButtons style={{ top: 40, right: 40 }}>
				<button className="save" onClick={saveEdit} style={{ width: 100 }}>
					저장
				</button>
			</ModalButtons>
			<ModalWrapper style={{ position: 'relative', height: 'auto', marginTop: 40 }}>
				<ModalDisplay style={{ height: 'auto', zIndex: 1 }}>
					<ItemTable style={{marginTop: 0}}>
						<tr>
							<th>아이디</th>
							<td>
								<ItemInput
									type="text"
									value={id}
									onChange={e => setId(e.target.value)}
									placeholder="아티스트의 고유 키 값을 입력해주세요."
								/>
							</td>
						</tr>
						<tr>
							<th>이름 *</th>
							<td>
								<ItemInput
									type="text"
									value={name}
									onChange={e => setName(e.target.value)}
									placeholder="아티스트의 이름을 입력해주세요.(필수입력)"
								/>
							</td>
						</tr>
						<tr>
							<th>다른 이름<br />(영문이나 한글로 바꾼 이름)</th>
							<td>
								<ItemInput
									type="text"
									value={koName}
									onChange={e => setKoName(e.target.value)}
									placeholder="아티스트의 이름을 입력해주세요.(필수입력)"
								/>
							</td>
						</tr>
						<tr>
							<th>출생</th>
							<td>
								<ItemInput
									type="text"
									value={birth}
									onChange={e => setBirth(e.target.value)}
									placeholder="아티스트의 출생연도를 입력해주세요."
								/>
							</td>
						</tr>
						<tr>
							<th>국적</th>
							<td>
								<ItemInput
									type="text"
									value={location}
									onChange={e => setLocation(e.target.value)}
									placeholder="아티스트의 국적을 입력해주세요."
								/>
							</td>
						</tr>
						<tr>
							<th>아티스트 정보 링크</th>
							<td>
								<ItemInput
									type="text"
									value={link}
									onChange={e => setLink(e.target.value)}
									placeholder="아티스트의 정보가 담긴 사이트 링크를 입력해주세요."
								/>
							</td>
						</tr>
						<tr>
							<th>아티스트 이미지 링크</th>
							<td>
								<ItemInput
									type="text"
									value={imageUrl}
									onChange={e => setImageLink(e.target.value)}
									placeholder="아티스트의 이미지 링크를 입력해주세요."
								/>

								<>
									{imageUrl && (
										<img
											src={`https://api.dosang.io/public/uploadImage${imageUrl}`}
											alt="artist"
											style={{width: '50px'}}
										/>
									)}
									<Upload
										action="https://run.mocky.io/v3/435e224c-44fb-4773-9faf-380c5e6a2188"
										listType="picture-card"
										fileList={fileList}
										onPreview={handlePreview}
										onChange={handleChange}>
										{uploadButton}
									</Upload>
									<Modal
										open={previewOpen}
										title={previewTitle}
										footer={null}
										onCancel={handleCancel}>
										<img alt="example" style={{width: '100%'}} src={previewImage}/>
									</Modal>
								</>
							</td>
						</tr>
					</ItemTable>
				</ModalDisplay>
			</ModalWrapper>
		</Content>
	)
}

export default React.memo(ArtistAdd)
