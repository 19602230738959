import React, { useState } from 'react'
import { Content, Header, Icon, SearchArea } from '../Main/styles';
import { ArtistListTable } from '../Artist/artistStyles';
import { useAuctionList } from 'app/Dosang/hooks/GetAuctionList';
import API from 'utils/api';
import { useToken } from 'app/hooks';
import { Input, message } from 'antd';
import AuctionModalEdit from 'app/Dosang/Components/AuctionModalEdit';
import { IoIosSearch } from "react-icons/io";

export const AuctionList = () => {
  const { auctionList, mutate } = useAuctionList();
  const { data: token } = useToken();
  const [isEdit, setIsEdit] = useState(false);
  const [editItem, setIsEditItem] = useState({});
  const [search, setSearch] = useState('');
  const filterList = auctionList?.filter((it) => it?.company?.toLowerCase().includes(search.toLowerCase()) || it?.title?.toLowerCase().includes(search.toLowerCase()));

  const clickDel = (id, name, title) => {
    const shouldDelete = window.confirm(`정말로 '${name}'에서 진행하는 \n '${title}' 를 삭제하시겠습니까?`);

    if(shouldDelete) {
      API.delete('/auction/schedule/list/' + id, token + '', {})
      .then((res: any) => {
        if (res.result.success) {
          mutate();
          message.success('삭제되었습니다');
        }
      })
      .catch((err: any) => {})
    }
  }

  const columns = [
    {
      dataIndex: 'name',
      title: '경매 사이트',
      render: (value, data) => {
        const company = data?.company;
        return (
          <div className='location'>
            <h5>{company}</h5>
          </div>
        )
      }
    },
    {
      dataIndex: 'title',
      title: '경매 내용',
      render: (value, data) => {
        const title = data?.title;
        return (
          <div className='name' style={{width: 300}}>
            <h5>{title}</h5>
          </div>
        )
      }
    },
    {
      dataIndex: 'startedAt',
      title: '시작일',
      render: (value, data) => {
        const startedAt = data?.startedAt;
        return (
          <div className='location'>
            <h5>{startedAt}</h5>
          </div>
        )
      }
    },
    {
      dataIndex: 'endedAt',
      title: '종료일',
      render: (value, data) => {
        const endedAt = data?.endedAt;
        return (
          <div className='link'>
            <h5>{endedAt}</h5>
          </div>
        )
      }
    },
    {
      dataIndex: 'edit',
      title: '수정/삭제',
      render: (value, data) => {
        const id = data?.id;
        const name = data?.company;
        return (
          <div className='edit'>
            <button className='editBtn' onClick={() => {setIsEdit(true); setIsEditItem(data)}}>수정</button>
            <button className='delBtn' onClick={() => clickDel(id, name, data?.title)}>삭제</button>
          </div>
        )
      }
    },
  ];

  return (
    <Content>
      <Header>
        경매 일정
        <SearchArea>
          <Input type='text' onChange={(e) => setSearch(e.target.value)} placeholder='경매 사이트, 내용 검색' />
          <Icon><IoIosSearch /></Icon>
        </SearchArea>
      </Header>
      <ArtistListTable dataSource={filterList} columns={columns} />
      {isEdit && <AuctionModalEdit setIsEdit={setIsEdit} editItem={editItem} />}
    </Content>
  )
}

export default React.memo(AuctionList);