import { Image, Input, message } from 'antd'
import ArtistModalEdit from 'app/Dosang/Components/ArtistModalEdit'
import { useArtistList } from 'app/Dosang/hooks/GetArtistList'
import { useToken } from 'app/hooks'
import React, {useEffect, useState} from 'react'
import { IoIosSearch } from 'react-icons/io'
import API from 'utils/api'
import { Content, Header, Icon, SearchArea } from '../Main/styles'
import { ArtistListTable } from './artistStyles'
import { Text } from '../../../../styles/reactnative'

export const ArtistList = () => {
	const { data: token } = useToken()

	const [page, setPage] = useState(1);
	const [pageSize, setPageSize] = useState(10);

	const [isEdit, setIsEdit] = useState(false)
	const [editItem, setIsEditItem] = useState({})
	const [search, setSearch] = useState('')

	const { artistList, total } = useArtistList(page, pageSize, search)
	// const filterList = artistList?.filter(
	// 	it =>
	// 		it?.name.toLowerCase().includes(search.toLowerCase()) ||
	// 		it?.orName?.toLowerCase().includes(search.toLowerCase())
	// )

	useEffect(() => {
		console.log('total', total)
	}, [total])

	const clickDel = (id, name) => {
		const shouldDelete = window.confirm(`정말로 '${name}' 를 삭제하시겠습니까?`)

		if (shouldDelete) {
			API.delete('/artist/list/' + id, token + '', {})
				.then((res: any) => {
					if (res.result.success) {
						message.success('삭제되었습니다')
					}
				})
				.catch((err: any) => {})
		}
	}

	const columns = [
		{
			dataIndex: 'id',
			title: 'ID',
			render: (value, data) => {
				const id = data?.id
				return (
					<div className="id">
						<h5>{id}</h5>
					</div>
				)
			},
		},
		{
			dataIndex: 'name',
			title: '이름',
			render: (value, data) => {
				const name = data?.name
				const orName = data?.orName
				return (
					<div className="name">
						<h5>{name}</h5>
						<h5>{orName}</h5>
					</div>
				)
			},
		},
		{
			dataIndex: 'birth',
			title: '출생',
			render: (value, data) => {
				const birth = data?.birth

				if (birth == 'CIRCA 1900') {
					return (
						<div className="birth">
							<h5>약 1900년경</h5>
						</div>
					)
				}
				return (
					<div className="birth">
						{birth !== '19TH CENTURY' && birth !== '20TH CENTURY' ? (
							<>
								<h5>{birth !== null ? birth : '?'}</h5>
							</>
						) : birth == '19TH CENTURY' ? (
							<h5>19세기</h5>
						) : (
							<h5>20세기</h5>
						)}
					</div>
				)
			},
		},
		{
			dataIndex: 'location',
			title: '국적',
			render: (value, data) => {
				const location = data?.location
				return (
					<div className="location">
						<h5>{location}</h5>
					</div>
				)
			},
		},
		{
			dataIndex: 'link',
			title: '아티스트 정보',
			render: (value, data) => {
				let link = data?.link

				if (link && !link.endsWith('/Artworks') && !link.endsWith('/artworks')) {
					link += '/Artworks';
				}

				return (
					<div className="link">
						<a href={link} target="_blank">
							{link}
						</a>
					</div>
				)
			},
		},
		{
			dataIndex: 'imageUrl',
			title: '이미지',
			render: (value, data) => {
				if (!value) return <Text>X</Text>
				return <Image width={50} src={`https://api.dosang.io/public/uploadImage/${value}`} />
			},
		},
		{
			dataIndex: 'edit',
			title: '수정/삭제',
			render: (value, data) => {
				const id = data?.id
				const name = data?.name
				return (
					<div className="edit">
						<button
							className="editBtn"
							onClick={() => {
								setIsEdit(true)
								setIsEditItem(data)
							}}>
							수정
						</button>
						<button className="delBtn" onClick={() => clickDel(id, name)}>
							삭제
						</button>
					</div>
				)
			},
		},
	]

	const handleTableChange = (pagination) => {
		setPage(pagination.current);
		setPageSize(pagination.pageSize);
	};


	return (
		<Content>
			<Header>
				아티스트 목록
				<SearchArea>
					<Input
						type="text"
						onChange={e => setSearch(e.target.value)}
						placeholder="아티스트 이름 검색"
					/>
					<Icon>
						<IoIosSearch />
					</Icon>
				</SearchArea>
			</Header>
			{/* {isLoading ? (
        <LoadingWrapper>
          <Spin size="large" />
          <LoadingTxt>데이터를 가져오는 중입니다...</LoadingTxt>
        </LoadingWrapper>
      ) : (
        <>
          <ArtistListTable dataSource={filterList} columns={columns} />
          {isEdit && <ArtistModalEdit setIsEdit={setIsEdit} editItem={editItem} />}
        </>
      )} */}
			<ArtistListTable
				dataSource={artistList}
				columns={columns}
				pagination={{
					current: page,
					pageSize: pageSize,
					total: total
				}}
				onChange={handleTableChange}
				loading={artistList?.length === undefined}
			/>
			{isEdit && <ArtistModalEdit setIsEdit={setIsEdit} editItem={editItem} />}
		</Content>
	)
}

export default React.memo(ArtistList)
