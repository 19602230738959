import React from 'react'
import { GlowEffect, HomeWrapper, Page1Display, Page1ImgWrapper, PageImg, PageSubhead, PageTitleArea, PageTitleTxt } from '../styles';
import Page1Img from '../../../assets/page1.png'

export const Page1 = () => {
  return (
    <HomeWrapper style={{backgroundColor: '#030303', height: 'auto'}}>
      <Page1Display>
        <PageTitleArea>
          <PageTitleTxt>[ 트렌디한 작가 순위 ]</PageTitleTxt>
          <PageSubhead>
            실시간으로 반영하는 전세계 작가 순위와<br /> 빠르게 트렌드를 알 수 있는 상승 작가 순위 집계,<br />
            관심있는 작가를 즐겨찾기에 모아두기까지.
          </PageSubhead>
        </PageTitleArea>
        <Page1ImgWrapper>
          <GlowEffect />
          <PageImg src={Page1Img} />
        </Page1ImgWrapper>
      </Page1Display>
    </HomeWrapper>
  )
}

export default React.memo(Page1);