import styled from "styled-components";
import '../../assets/font.css'
import { Icon } from "../Main/styles";
import { Text } from "styles/reactnative";

export const HomeWrapper = styled.div`
  overflow: hidden;
  height: 100vh;
  background-color: #1F1F1F;
  @media screen and (max-width: 1200px) {
    overflow-y: auto;
  }
`;
export const HomeBackground = styled.img`
  width: 100vw;
  height: 100vh;
  object-fit: cover;
  z-index: 9;
  @media screen and (max-width: 1600px) {
    left: 300px;
  }
  @media screen and (max-width: 1200px) {
    object-fit: cover;
    object-position: 70%;
    height: 100%;
    width: 100%;
    position: absolute;
    top: 385px;
    left: 0;
  }
  @media screen and (max-width: 600px) {
    object-fit: cover;
    object-position: 67%;
    top: 274px;
  }
`;
export const HomeDisplay = styled.div`
  position: absolute;
  top: 200px;
  left: 360px;
  color: #fff;
  @media screen and (max-width: 1600px) {
    left: 300px;
  }
  @media screen and (max-width: 1400px) {
    left: 200px;
  }
  @media screen and (max-width: 1200px) {
    display: flex;
    flex-direction: column;
    align-items: center;
    top: 100px;
    left: 50%;
    transform: translateX(-50%);
    z-index: 99;
  }
`
export const HomeTitle = styled.div`
  display: flex;
  flex-direction: column;
  gap: 20px;
  @media screen and (max-width: 1200px) {
    align-items: center;
  }
  @media screen and (max-width: 600px) {
    gap: 16px;
  }
`
export const HomeTitleTxt = styled.h1`
  color: #fff;
  font-family: 'Pretendard';
  font-size: 28px;
  font-weight: 600;
    display: flex;
    gap: 5px;

    @media screen and (max-width: 1200px) {
        font-size: 5.8vw;
    }
    
`
export const HomeTitleImg = styled.img`
  width: 310px;
  height: 50px;
  object-fit: contain;
  scale: 4.3;
  @media screen and (max-width: 1200px) {
    width: 19vw;
  
  }
`
export const PickerWrapper = styled.div`
  margin-top: 67px;
  z-index: 99;
  .picker-container {
    z-index: 99;
    .picker-inner {
      padding: 0;
      .picker-highlight {
        display: none;
      }
      .picker-column {
        .picker-scroller {
          .picker-item {
            font-family: 'Pretendard';
            text-align: left;
            font-size: 22px;
            z-index: 99;
          }
          .picker-item-selected {
            color: #fff;
            font-size: 28px;
            font-weight: bold;
            z-index: 99;
          }
        }
      }
    }
  }
  @media screen and (max-width: 1200px) {
    margin-top: 37px;
    .picker-container {
      .picker-inner {
        .picker-column {
          .picker-scroller {
            .picker-item {
              text-align: center;
              font-size: 18px;
            }
            .picker-item-selected {
              color: #fff;
              font-size: 22px;
              font-weight: bold;
            }
          }
        }
      }
    }
  }
  @media screen and (max-width: 600px) {
    margin-top: 44px;
  }
` 
export const AppDownBtns = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: 136px;
  gap: 16px;
  @media screen and (max-width: 1200px) {
    margin-top: 50px;
  }
  @media screen and (max-width: 600px) {
    display: none;
  }
`
export const AppDownBtn = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 10px;
  width: 200px;
  height: 54px;
  border-radius: 2px;
  border: 1px solid #404040;
  background-color: #151515;
  color: #fff;
  font-size: 18px;
  font-family: 'nanumSquareNeo';
  font-weight: 400;
  letter-spacing: -0.72px;
  cursor: pointer;
  ${Icon} {
    width: 24px;
    height: 24px;
    > svg {
      width: 100%;
      height: 100%;
    }
  }
`
export const MobileAppDownBtn = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 8px;
  width: calc(100vw - 32px);
  height: 60px;
  border-radius: 2px;
  border: 1px solid #404040;
  background-color: rgba(21, 21, 21, 0.80);
  font-family: 'nanumSquareNeo';
  position: fixed;
  bottom: 14px;
  left: 16px;
  display: none;
  z-index: 99;
  h6 {
    color: #fff;
    font-size: 16px;
    font-weight: 500;
  }
  @media screen and (max-width: 600px) {
    display: flex;
  }
`

export const Page1Display = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 83px;
  height: auto;
  padding-top: 299px;
  overflow: hidden;
  @media screen and (max-width: 1200px) {
    padding-top: 600px;
  }
  @media screen and (max-width: 520px) {
    padding-top: 370px;
  }
`
export const Page2Display = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  padding-top: 200px;
  width: 100vw;
  gap: 100px;
  margin-left: -50px;
  overflow: hidden;
  @media screen and (max-width: 520px) {
    padding-top: 100px;
  }
`
export const Page3Display = styled.div`
  display: flex;
  align-items: center;
  flex-direction: column;
  padding-top: 350px;
  width: 100vw;
  overflow: hidden;
  @media screen and (max-width: 520px) {
    padding-top: 150px;
  }
`
export const PageTitleArea = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 50px;
  z-index: 3;
`
export const PageTitleTxt = styled.h1`
  font-size: 50px;
  font-style: normal;
  font-family: 'Pretendard';
  letter-spacing: -1.5px;
  font-weight: 700;
  background: linear-gradient(180deg, #FFF 25.97%, #929292 68.33%, #858484 68.33%);
  background-clip: text;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  @media screen and (max-width: 1000px) {
    font-size: 38px;
  }
  @media screen and (max-width: 700px) {
    font-size: 28px;
  }
`
export const PageSubhead = styled.h4`
  width: 718px;
  color: #CFCFCF;
  font-size: 22px;
  font-family: 'Pretendard';
  letter-spacing: -0.88px;
  font-weight: 500;
  text-align: center;
  white-space: pre-wrap;
  @media screen and (max-width: 1000px) {
    font-size: 18px;
  }
  @media screen and (max-width: 520px) {
    font-size: 16px;
    padding: 0px 10px;
  }
`
export const Page1ImgWrapper = styled.div`
  width: auto;
  height: auto;
  position: relative;
  z-index: 1;
`
export const PageImg = styled.img`
  width: 100%;
  height: 100%;
  object-fit: contain;
  background-color: transparent;
  z-index: 1;
  @media screen and (max-width: 650px) {
    &.page2 {
      
    }
  }
`
export const GlowEffect = styled.div`
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 1000px;
  height: 1000px; 
  border-radius: 50%;
  background: radial-gradient(circle,rgba(255, 255, 255, 0.164), #030303 50%);
  z-index: -1;
`;

export const Page2NewsWrapper = styled.div`
  display: flex;
  flex-direction: column;
  width: 354px;
  height: 264px;
  background-color: transparent;
  font-family: 'nanumSquareNeo';
  margin-top: 121px;
  @media screen and (max-width: 850px) {
    width: 250px;
  }
  @media screen and (max-width: 520px) {
    width: 160px;
    margin-top: 0px;
    height: auto;
  }
`
export const NewsHeader = styled.div`
  display: flex;
  align-items: center;
  width: 100%;
  padding: 4px;
  gap: 18px;
  margin-bottom: 28px;
  @media screen and (max-width: 520px) {
    gap: 5px;
  }
  @media screen and (max-width: 520px) {
    margin-bottom: 10px;
  }
`
export const NewsTab = styled.div`
  flex: 1;
  padding: 10px;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 4px;
  color: #A0A0A0;
  font-size: 14px;
  font-style: normal;
  font-weight: 700;
  &.news {
    color: #fff;
    border-bottom: 2px solid #fff;
  }
  @media screen and (max-width: 1000px) {
    font-size: 12px;
  }
  @media screen and (max-width: 520px) {
    padding: 10px 0px;
    font-size: 10px;
  }
`
export const NewsContent = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 16px 0;
  @media screen and (max-width: 850px) {
    padding: 8px 0;
    align-items: flex-start;
    gap: 10px;
  }
  @media screen and (max-width: 520px) {
    padding: 4px 0;
  }
`
export const NewsDetail = styled.div`
  width: 220px;
  display: flex;
  flex-direction: column;
  gap: 8px;
  align-items: flex-start;
  @media screen and (max-width: 520px) {
    width: 150px;
  }
`
export const NewsTitle = styled(Text)`
  font-family: 'nanumSquareNeo';
  color: #E1E1E1;
  font-size: 15px;
  font-weight: 500;
  justify-content: flex-start;
  @media screen and (max-width: 850px) {
    font-size: 13px;
  }
  @media screen and (max-width: 520px) {
    font-size: 10px;
  }
`
export const NewsInfo = styled(Text)`
  font-family: 'nanumSquareNeo';
  color: #a4a4a4;
  font-size: 13px;
  font-weight: 400;
  justify-content: flex-start;
  @media screen and (max-width: 850px) {
    font-size: 11px;
  }
  @media screen and (max-width: 520px) {
    font-size: 9px;
  }
`
export const NewsThumbnail = styled.div`
  width: 90px;
  height: 60px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 2px;
  @media screen and (max-width: 850px) {
    width: 70px;
    height: 40px;
    > img {
      width: 100%;
      height: 100%;
    }
  }
  @media screen and (max-width: 520px) {
    width: 50px;
    height: 30px;
  }
`
export const Hr = styled.hr`
  width: 100%;
  border: 1px solid #272727;
`
export const Page4ImgWrapper = styled.div`
  position: relative;
  @media screen and (max-width: 1200px) {
    display: flex;
  }
  @media screen and (max-width: 800px) {
    padding: 0 50px;
  }
  @media screen and (max-width: 520px) {
    margin-top: -100px;
  }
`
export const Page3TxtArea = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-around;
  width: 766px;
  margin-top: -59px;
  @media screen and (max-width: 870px) {
    width: 100%;
    padding-left: 50px;
    padding-right: 50px;
  }
`
export const Page3Txt = styled.h3`
  color: #fff;
  font-style: 16px;
  font-weight: 500;
  font-family: 'Pretendard';
  letter-spacing: -0.64px;
  flex: 1;
  text-align: center;
  @media screen and (max-width: 870px) {
    font-size: 14px;
  }
`