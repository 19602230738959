import React, {useEffect, useState} from 'react'
import { Content, Header, Icon, LoadingTxt, LoadingWrapper, SearchArea } from '../../Main/styles';
import { useArtistList } from 'app/Dosang/hooks/GetArtistList';
import { Input, Spin, message } from 'antd';
import API from 'utils/api';
import { useToken } from 'app/hooks';
import AddArtistModal from 'app/Dosang/Components/ArtistModalEdit';
import ArtistModalEdit from 'app/Dosang/Components/ArtistModalEdit';
import { IoIosSearch } from "react-icons/io";
import { ArtistListTable } from '../../Artist/artistStyles';
import { useBoardList } from 'app/Dosang/hooks/GetBoard';
import NoticeEdit from 'app/Dosang/Components/NoticeEdit';
import {useNewsFeed} from "../../../hooks/GetNewsFeed";

export const NewsList = () => {
  const { data: token } = useToken();
  const { boardList, mutate } = useBoardList('news');
  const { newsFeedData, mutate:newsFeedMutate } = useNewsFeed();
  const [isEdit, setIsEdit] = useState(false);
  const [editItem, setIsEditItem] = useState({});
  const [search, setSearch] = useState('');
  const filterList = boardList?.filter((it) => it?.title.toLowerCase().includes(search.toLowerCase()) || it?.content?.toLowerCase().includes(search.toLowerCase()));

  const clickDel = (id, name) => {
    const shouldDelete = window.confirm(`정말로 '${name}' 를 삭제하시겠습니까?`);

    if(shouldDelete) {
      API.delete('/board/news?postIds=' + id, token + '', {})
      .then((res: any) => {
        if (res.result.success) {
          message.success('삭제되었습니다');
          mutate()
        }
      })
      .catch((err: any) => {})
    }
  }


  const putNewsFeed = (id) => {
    const shouldDelete = window.confirm(`메인피드로 설정하시겠습니까?`);

    if(shouldDelete) {
      API.put('/board/newsfeed', token + '', {
        datas:{
          postId: id
        }
      })
        .then((res: any) => {
          if (res.result.success) {
            message.success('메인피드로 설정되었습니다.');
            newsFeedMutate()
          } else {
            message.error(res.result.message || '실패하였습니다.');
          }
        })
        .catch((err: any) => {
          console.log('메인피드 설정 실패 -> ', err)
        })
    }
  }


  const columns = [
    {
      dataIndex: 'name',
      title: '제목',
      render: (value, data) => {
        const title = data?.title;
        return (
          <div className='title'>
            <h5>{title}</h5>
          </div>
        )
      }
    },
    {
      dataIndex: 'birth',
      title: '내용',
      render: (value, data) => {
        const content = data?.content;
        return (
          <div className='content'>
            <h5>{content}</h5>
          </div>
        )
      }
    },
    {
      dataIndex: 'location',
      title: '공지일',
      render: (value, data) => {
        const rdate = data?.rdate;
        return (
          <div className='id'>
            <h5>{rdate}</h5>
          </div>
        )
      }
    },
    {
      dataIndex: 'link',
      title: '작성자',
      render: (value, data) => {
        const writer = data?.writer;
        return (
          <div className='location'>
            <h5>{writer}</h5>
          </div>
        )
      }
    },
    {
      dataIndex: 'edit',
      title: '삭제',
      render: (value, data) => {
        const id = data?.id;
        const title = data?.title;
        return (
          <div className='edit'>
            {/*<button className='editBtn' onClick={() => {setIsEdit(true); setIsEditItem(data)}}>수정</button>*/}
            <button className='delBtn' onClick={() => clickDel(id, title)}>삭제</button>
          </div>
        )
      }
    },
    {
      dataIndex: '',
      title: '메인피드',
      render: (value, data) => {
        const id = data?.id;
        return (
          <div className='edit'>
            {newsFeedData?.id !== data?.id ?
              <button className='editBtn' onClick={() => putNewsFeed(id)}>설정</button>
              :
              <h5 style={{color: '#8863e8'}}>{'메인피드'}</h5>
            }
          </div>
        )
      }
    },
  ];

  return (
    <Content>
    <Header>
        공지 목록
        <SearchArea>
          <Input type='text' onChange={(e) => setSearch(e.target.value)} placeholder='공지 제목 또는 내용 검색' />
          <Icon><IoIosSearch /></Icon>
        </SearchArea>
      </Header>
      <ArtistListTable dataSource={filterList} columns={columns} />
      {isEdit && <NoticeEdit setIsEdit={setIsEdit} editItem={editItem} />}
    </Content>
  )
}

export default React.memo(NewsList);