import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { apiError, checkEmail } from 'utils/ExpensiveFunction'
import ErrorHandling from './ErrorHandling'
import API from 'utils/api'
import { useToken, useUser } from 'app/hooks'
import styled from 'styled-components'
import { adminPageLogo } from 'app/constants/IMAGES'
import { useHistory } from 'react-router-dom'
import { message } from 'antd'
import NaverLoginButton from './NaverLoginButton'
import GoogleLoginButton from './GoogleLoginButton'
import KakaoLoginButton from './KakaoLoginButton'
import '../../../Dosang/assets/font.css'
import dosangLogo from '../../../Dosang/assets/dosangLogo.png';


const LoginForm = ({ setLoginSuccess }) => {
	const { t } = useTranslation()

	const [error, setError] = useState<string>('')
	const [success, setSuccess] = useState<string>('')
	const { mutate } = useToken()
	const { mutate: usernameMutate } = useUser()
	const navigate = useHistory()

	const [email, setEmail] = useState('')
	const [password, setPassword] = useState('')

	const onChangeEmail = e => {
		setEmail(e.target.value)
	}

	const onChangePassword = e => {
		setPassword(e.target.value)
	}

	const handleSubmit = async () => {
		const res = checkEmail(email)
		if (typeof res === 'string') return setError(res)

		try {
			// const fmsToken = fmsService.requestPermission()
			const fmsToken = ''
			const res = await API.send('POST', '/auth/signin', null, {
				username: email,
				password,
				fmsToken,
			})

			if (res.result.success) {
				mutate(res?.result?.token)
				usernameMutate(res?.result?.username)
				message.success('로그인 성공!')
			} else {
				const errorResult = apiError(t, res.result.msgcode)
				setError(t(errorResult))
			}
		} catch (e) {
			console.log(e, 'ERROR LOGIN')
		}
	}

	return (
		<>
			<LoginWrap>
				<LoginLogo>
					<img style={{ width: '50%' }} alt={'dosangLogo'} src={dosangLogo}></img>
					<LoginTitle>관리자 로그인</LoginTitle>
				</LoginLogo>
				{error?.length > 3 ? <ErrorHandling success={success} error={error} /> : null}
				<LoginInputWrap>
					<LoginInput placeholder="관리자 아이디" onChange={onChangeEmail}></LoginInput>
					<LoginInput
						type="password"
						placeholder="관리자 비밀번호"
						onChange={onChangePassword}></LoginInput>
				</LoginInputWrap>
				<LoginBottomWrap>
					<LoginNotice>계정은 관리자에게 문의해주세요</LoginNotice>
					<LoginButton
						onClick={event => {
							event.preventDefault()
							handleSubmit()
						}}>
						로그인
					</LoginButton>
				</LoginBottomWrap>
			</LoginWrap>
		</>
	)
}

export default React.memo(LoginForm)

const LoginWrap = styled.div`
	font-family: 'nanumSquareNeo';
	box-sizing: border-box;

	/* Auto layout */

	display: flex;
	flex-direction: column;
	align-items: center;
	padding: 50px 40px;

	position: fixed;
	/* width: 100%;
	height: 100%;	 */
	width: 440px;

	left: 50%;
	top: 50%;
	transform: translate(-50%, -50%);
	/* main */

	border: 1px solid #2c3037;
	background-color: #2c3037;
	border-radius: 10px;
`
const LoginLogo = styled.div`
	/* width: 160px; */
	/* height: 64px; */
	/* margin: 0 auto; */

	/* Inside auto layout */
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;
	gap: 16px;
	color: #fff;
	p {
		font-size: 26px;
		font-weight: 700;
		letter-spacing: -1px;
	}
`
const LoginTitle = styled.div`
	width: 100%;
	height: 100%;
	/* max-width: 115px; */
	/* max-height: 29px; */
	font-weight: 500;
	font-size: 18px;
	/* identical to box height */

	text-align: center;

	/* black */

	color: #EFEFEF;

	/* Inside auto layout */

	flex: none;
	order: 1;
	flex-grow: 0;
`
const LoginInputWrap = styled.div`
	/* Auto layout */

	display: flex;
	flex-direction: column;
	align-items: flex-start;
	padding: 0px;
	gap: 12px;

	width: 100%;
	height: 100%;
	width: 360px;
	height: 112px;

	/* Inside auto layout */

	flex: none;
	order: 2;
	flex-grow: 0;

	margin: 42px 0px;
`
const LoginInput = styled.input`
	/* input */

	box-sizing: border-box;

	/* Auto layout */

	display: flex;
	flex-direction: row;
	align-items: flex-start;
	padding: 12px 16px;

	width: 100%;
	height: 100%;
	max-width: 360px;
	max-height: 50px;
	background-color: #fff;

	/* stroke */
	border: 1px solid #Fff;
	border-radius: 2px;

	font-size: 15px;
	/* color: #800F09 !important; */
	font-weight: 500;
	font-family: 'nanumSquareNeo';

	&:focus {
		/* border: 1.5px solid #800F09; */
	}
	&::placeholder {
		/* color: #C74C4566; */
		font-weight: 500;
		font-size: 15px;
		font-family: 'nanumSquareNeo';
	}
`
const LoginBottomWrap = styled.div`
	/* Auto layout */

	display: flex;
	flex-direction: column;
	align-items: center;
	padding: 0px;
	gap: 24px;

	width: 100%;
	height: 100%;
	max-width: 360px;
	max-height: 104px;

	/* Inside auto layout */

	flex: none;
	order: 3;
	flex-grow: 0;
`
const LoginNotice = styled.div`
	width: 100%;
	height: 100%;
	/* max-width: 191px;
	max-height: 20px; */

	font-family: 'Noto Sans KR';
	font-style: normal;
	font-weight: 400;
	font-size: 14px;
	line-height: 20px;

	/* text/secondary */

	color: #FFF;
	text-align: center;
	font-size: 14px;

	/* Inside auto layout */

	flex: none;
	order: 0;
	flex-grow: 0;
`
const LoginButton = styled.button`
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
	padding: 0px;

	width: 100%;
	max-width: 360px;
	height: 60px;

	background: #FFF;
	border-radius: 2px;
	border: none;
	flex: none;
	order: 1;
	flex-grow: 0;

	color: #2c3037;
	font-size: 18px;
	font-weight: 700;
	font-family: 'nanumSquareNeo';
`
