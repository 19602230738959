import useSWR from 'swr'
import API from '../../../utils/api'
import { getPersistData } from '../../hooks/persist'
import { useToken } from 'app/hooks'

const KEY = '/users' // (1) 사용자 목록 조회

let swrData: any = getPersistData(KEY)

export function useUserList() {
	const { data: token } = useToken()
	const { data, mutate } = useSWR<any>(
		KEY + '?page=1&maxData=1000',
		async () => {
			const response = await API.get(KEY + '?page=1&maxData=1000', token + '', {})
			swrData = response.result?.result?.list
			
			return swrData
		},
		{ revalidateOnFocus: true }
	)

	return {
		userList: data,
		mutate: (value?: any) => {
			if (value != undefined) {
				swrData = value
			}
			return mutate()
		},
	}
}