import React, { useState } from 'react'
import { Content, Header } from '../Main/styles';
import { ItemInput, ItemTable, ModalButtons, ModalDisplay, ModalHeader, ModalWrapper } from '../../Components/ModalStyles';
import { Select, message } from 'antd';
import { useToken } from 'app/hooks';
import API from 'utils/api';
import { useAuctionList } from 'app/Dosang/hooks/GetAuctionList';
import { useAuctionSiteList } from 'app/Dosang/hooks/GetAuctionSiteList';
import { useHistory } from 'react-router-dom';


export const AuctionAdd = () => {
  const { data: token } = useToken();
  const { mutate } = useAuctionList();
  const { auctionSiteList } = useAuctionSiteList('');
  const [company, setCompany] = useState(auctionSiteList?.[0]?.name);
  const [title, setTitle] = useState('');
  const [startedAt, setStartedAt] = useState('');
  const [endedAt, setEndedAt] = useState('');
  const navigate = useHistory();

  const saveEdit = () => {
    const shouldEdit = window.confirm(`등록하실 내용이 맞습니까?\n회사명: ${company}\n일정: ${title}\n시작일: ${startedAt}\n종료일: ${endedAt}`);
    if(shouldEdit) {
      if(company == '' && title == '' && startedAt == '' && endedAt == '') {
        message.error('등록하실 경매 정보를 입력해주세요.')
      } else if (company == '') {
        message.error('회사명은 필수 입력입니다.')
      } else {
        API.post('/auction/schedule/list', token + '', {
          company: company,
          type: 'string',
          title: title,
          round: 1,
          startedAt: startedAt,
          endedAt: endedAt,
        })
        .then((res: any) => {
          if (res.result.success) {
            message.success('등록되었습니다');
            navigate.push('/dsadmin/auction/list');
            mutate();
          }
        })
        .catch((err: any) => {})
      }
    }
  }

  return (
    <Content style={{position: 'relative'}}>
      <Header>경매 수동 등록</Header>
      <ModalButtons style={{top: 40, right: 40}}>
        <button className='save' onClick={saveEdit} style={{width: 100}}>저장</button>
      </ModalButtons>
      <ModalWrapper style={{position: 'relative', height: 'auto', marginTop: 40}}>
        <ModalDisplay style={{height: 'auto', zIndex: 1}}>
          <ItemTable style={{marginTop: 0}}>
            <tr>
              <th>경매 사이트 *</th>
              <td>
                <Select value={company} onChange={(e) => setCompany(e)} style={{width: 200}}>
                  {auctionSiteList?.map((it) => (
                    <option style={{fontFamily: 'nanumSquareNeo'}} value={it.name}>{it.name}</option>
                  ))}
                </Select>
              </td>
            </tr>
            <tr>
              <th>경매 내용</th>
              <td><ItemInput type='text' value={title} onChange={(e) => setTitle(e.target.value)} placeholder='경매 내용을 입력해주세요.' /></td>
            </tr>
            <tr>
              <th>시작일</th>
              <td><ItemInput type='date' value={startedAt} onChange={(e) => setStartedAt(e.target.value)} /></td>
            </tr>
            <tr>
              <th>종료일</th>
              <td><ItemInput type='date' value={endedAt} onChange={(e) => setEndedAt(e.target.value)} /></td>
            </tr>
          </ItemTable>
        </ModalDisplay>
      </ModalWrapper>
    </Content>
  )
}

export default React.memo(AuctionAdd);