import React, { useState } from 'react'
import { Content, Header } from '../Main/styles';
import { ItemInput, ItemTable, ModalButtons, ModalDisplay, ModalHeader, ModalWrapper } from '../../Components/ModalStyles';
import { Select, message } from 'antd';
import { useToken } from 'app/hooks';
import API from 'utils/api';
import { useAuctionSiteList } from 'app/Dosang/hooks/GetAuctionSiteList';
import { useArtworkList } from 'app/Dosang/hooks/GetArtWorkList';

export const AuctionArtworkAdd = () => {
  const { data: token } = useToken();
  const { mutate } = useArtworkList();
  const { auctionSiteList } = useAuctionSiteList('');
  const [artistName, setArtistName] = useState('');
  const [title, setTitle] = useState('');
  const [price, setPrice] = useState('');
  const [date, setDate] = useState('');
  const [company, setCompany] = useState(auctionSiteList?.[0]?.name);

  const saveEdit = () => {
    const shouldEdit = window.confirm(`등록하실 내용이 맞습니까?\n경매 사이트: ${company}\n아티스트: ${artistName}\n작품명: ${title}\n가격: ${price}\n등록일: ${date}`);
    if(shouldEdit) {
      if(artistName == '' && title == '' && price == '' && date == '' && company == null) {
        message.error('등록하실 작품 정보를 입력해주세요.')
      } else if (title == '') {
        message.error('작품명은 필수 입력입니다.')
      } else {
        API.post('/auction/lot/list/', token + '', {
          auctionId: auctionSiteList?.find((it) => it.name == company ? it.id : ''),
          artistName: artistName,
          title: title,
          price: price,
          date: date,
        })
        .then((res: any) => {
          if (res.result.success) {
            message.success('등록되었습니다');
            mutate();
          }
        })
        .catch((err: any) => {})
      }
    }
  }

  return (
    <Content style={{position: 'relative'}}>
      <Header>작품 등록</Header>
      <ModalButtons style={{top: 40, right: 40}}>
        <button className='save' onClick={saveEdit} style={{width: 100}}>저장</button>
      </ModalButtons>
      <ModalWrapper style={{position: 'relative', height: 'auto', marginTop: 40}}>
        <ModalDisplay style={{height: 'auto', zIndex: 1}}>
          <ItemTable style={{marginTop: 0}}>
            <tr>
              <th>경매 사이트</th>
              <td>
                <Select onChange={(e) => setCompany(e)} value={company}>
                  {auctionSiteList?.map((it) => (
                    <option value={it.id}>{it?.name}</option>
                  ))}
                </Select>
              </td>
            </tr>
            <tr>
              <th>아티스트 이름</th>
              <td><ItemInput type='text' value={artistName} onChange={(e) => setArtistName(e.target.value)} /></td>
            </tr>
            <tr>
              <th>작품명 *</th>
              <td><ItemInput type='text' value={title} onChange={(e) => setTitle(e.target.value)} /></td>
            </tr>
            <tr>
              <th>가격</th>
              <td><ItemInput type='text' value={price} onChange={(e) => setPrice(e.target.value)} /></td>
            </tr>
            <tr>
              <th>등록일</th>
              <td><ItemInput type='date' value={date} onChange={(e) => setDate(e.target.value)} /></td>
            </tr>
          </ItemTable>
        </ModalDisplay>
      </ModalWrapper>
    </Content>
  )
}

export default React.memo(AuctionArtworkAdd);