import React, { useState } from 'react'
import { Blacked, ItemInput, ItemTable, ModalButtons, ModalDisplay, ModalHeader, ModalWrapper } from './ModalStyles';
import { Select, message } from 'antd';
import { useToken } from 'app/hooks';
import API from 'utils/api';
import { useAuctionSiteList } from '../hooks/GetAuctionSiteList';
import { useArtworkList } from '../hooks/GetArtWorkList';

export const ArtistModalEdit = ({ setIsEdit, editItem }) => {
  const { data: token } = useToken();
  const { mutate } = useArtworkList();
  const { auctionSiteList } = useAuctionSiteList('');
  const [artistName, setArtistName] = useState(editItem?.artistName);
  const [title, setTitle] = useState(editItem?.title);
  const [price, setPrice] = useState(editItem?.price);
  const [date, setDate] = useState(editItem?.date);
  const [company, setCompany] = useState(editItem?.company);

  const saveEdit = () => {
    const shouldEdit = window.confirm(`수정하실 내용이 맞습니까?\n경매 사이트: ${company}\n아티스트: ${artistName}\n작품명: ${title}\n가격: ${price}\n등록일: ${date}`);
    if(shouldEdit) {
      API.put('/auction/lot/list/' + editItem?.id, token + '', {
        datas: {
          auctionId: auctionSiteList?.find((it) => it.name == company ? it.id : ''),
          artistName: artistName,
          title: title,
          price: price,
          date: date,
        }
      })
      .then((res: any) => {
        if (res.result.success) {
          message.success('수정되었습니다');
          mutate();
          setIsEdit(false);
        }
      })
      .catch((err: any) => {})
    }
  }

  return (
    <ModalWrapper>
      <Blacked />
      <ModalDisplay style={{width: 1000}}>
        <ModalHeader>
          작품 수정
          <ModalButtons>
            <button className='cancel' onClick={() => setIsEdit(false)}>취소</button>
            <button className='save' onClick={saveEdit}>저장</button>
          </ModalButtons>
        </ModalHeader>
        <ItemTable>
          <tr>
            <th>경매 사이트</th>
            <td>
              <Select onChange={(e) => setCompany(e)} value={company}>
                {auctionSiteList?.map((it) => (
                  <option style={{fontFamily: 'nanumSquareNeo'}} value={it.id}>{it?.name}</option>
                ))}
              </Select>
            </td>
          </tr>
          <tr>
            <th>아티스트 이름</th>
            <td><ItemInput type='text' value={artistName} onChange={(e) => setArtistName(e.target.value)} /></td>
          </tr>
          <tr>
            <th>작품명</th>
            <td><ItemInput type='text' value={title} onChange={(e) => setTitle(e.target.value)} /></td>
          </tr>
          <tr>
            <th>가격</th>
            <td><ItemInput type='text' value={price} onChange={(e) => setPrice(e.target.value)} /></td>
          </tr>
          <tr>
            <th>등록일</th>
            <td><ItemInput type='date' value={date} onChange={(e) => setDate(e.target.value)} /></td>
          </tr>
        </ItemTable>
      </ModalDisplay>
    </ModalWrapper>
  )
}

export default React.memo(ArtistModalEdit);