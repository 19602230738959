import styled from "styled-components";
import '../../assets/font.css'
import { Input } from "antd";

export const DosangWrapper = styled.div`
  font-family: 'nanumSquareNeo';
  display: flex;
  justify-content: center;
  padding: 40px 0;
  background-color: #191d21;
  color: #fff;
  font-weight: 400;
  overflow: hidden;
  ::-webkit-scrollbar-thumb {
    background-color: #191d21;
  }
`
export const DosangDisplay = styled.div`
  width: 1200px;
  border-radius: 30px;
  background-color: #2c3037;
  border: 2px solid #e0e3e6dd;
  display: flex;
`
export const Icon = styled.div`
  width: 18px;
  height: 18px;
  display: flex;
  align-items: center;
  justify-content: center;
`
export const Content = styled.div`
  padding: 40px;
  width: 1000px;
`
export const Header = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  color: #fff;
  font-size: 22px;
  font-weight: 700;
  input[type=text] {
    width: 200px;
    border: none;
    font-family: 'nanumSquareNeo';
  }
`
export const SearchArea = styled.div`
  width: 230px;
  display: flex;
  align-items: center;
  gap: 4px;
  background-color: #fff;
  border-radius: 5px;
  ${Icon} {
    color: #2c3037;
  }
`
export const WelcomeWrapper = styled.div`
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: center;
  gap: 30px;
  width: calc(100% - 200px);
  height: 50%;
`
export const WelcomeTxt = styled.h1`
  font-size: 28px;
  font-family: 'nanumSquareNeo';
  font-weight: 900;
  color: #fff;
`

export const LoadingWrapper = styled.div`
  width: 100%;
  height: 80%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 30px;
`
export const LoadingTxt = styled.h1`
  font-size: 28px;
  font-family: 'nanumSquareNeo';
  font-weight: 900;
  color: #fff;
  animation: wave 1.5s infinite;
  @keyframes wave {
  0% {
    transform: translateY(0);
  }
  50% {
    transform: translateY(-30%);
  }
  100% {
    transform: translateY(0);
  }
}
`
