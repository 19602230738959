import React, { useState } from 'react'
import { Blacked, ItemInput, ItemTable, ModalButtons, ModalDisplay, ModalHeader, ModalWrapper } from './ModalStyles';
import { message } from 'antd';
import { useToken } from 'app/hooks';
import API from 'utils/api';
import { useUserList } from '../hooks/GetUserList';

export const UserEdit = ({ setIsEdit, editItem }) => {
  const { data: token } = useToken();
  const { mutate } = useUserList();
  const [email, setEmail] = useState(editItem?.email || '');
  const [name, setName] = useState(editItem?.name || '');
  const [password, setPassword] = useState(editItem?.password);
  const [passwordCheck, setPasswordCheck] = useState('');
  const [phone, setPhone] = useState(editItem?.phone || '');
  const [isPassword, setIsPassword] = useState(false);

  const saveEdit = () => {
    const shouldEdit = window.confirm(`수정하실 내용이 맞습니까?\n Email: ${email}\n이름: ${name}\n핸드폰 번호: ${phone}`);
    if(shouldEdit) {
      API.put('/users/' + editItem?.id, token + '', {
        datas: {
          email: email,
          password: password,
          name: name,
          phone: phone,
        }
      })
      .then((res: any) => {
        if (res.result.success) {
          message.success('수정되었습니다');
          mutate();
          setIsEdit(false);
        }
      })
      .catch((err: any) => {})
    }
  }

  const checkPassword = () => {
    if(password != '') {
      if(passwordCheck === password) {
        message.success('비밀번호가 확인되었습니다.');
      } else {
        message.error('비밀번호가 일치하지 않습니다.');
      }
    } else {
      message.error('비밀번호를 입력해주세요.');
    }
  }

  return (
    <ModalWrapper>
      <Blacked />
      <ModalDisplay style={{width: 1000}}>
        <ModalHeader>
          사용자 수정
          <ModalButtons>
            <button className='cancel' onClick={() => setIsEdit(false)}>취소</button>
            <button className='save' onClick={saveEdit}>저장</button>
          </ModalButtons>
        </ModalHeader>
        <ItemTable>
          <tr>
            <th>Email *</th>
            <td><ItemInput type='text' value={email} onChange={(e) => setEmail(e.target.value)} placeholder='사용자 이메일을 입력해주세요.(필수입력)' /></td>
          </tr>
          <tr>
            <th>이름 *</th>
            <td><ItemInput type='text' value={name} onChange={(e) => setName(e.target.value)} placeholder='사용자 이름을 입력해주세요.(필수입력)' /></td>
          </tr>
          <tr>
            <th>핸드폰 번호</th>
            <td><ItemInput type='text' value={phone} onChange={(e) => setPhone(e.target.value)} placeholder='사용자 핸드폰 번호를 입력해주세요.' /></td>
          </tr>
          <tr>
            <th>비밀번호</th>
            <td>
              {isPassword ? (
                <div style={{display: 'flex', justifyContent: 'space-between'}}>
                  <ItemInput style={{width: '80%'}} type='password' value={password} onChange={(e) => setPassword(e.target.value)} placeholder='변경할 비밀번호를 입력해주세요.' />
                  <button onClick={() => {
                    setIsPassword(false)
                    setPassword('')
                  }} style={{backgroundColor: '#f53b3b', border: '1px solid #f53b3b'}}>
                    취소
                  </button>
                </div>
              ) : (
                <button onClick={() => setIsPassword(true)} style={{marginLeft: 0, backgroundColor: '#2c3037', border: '1px solid #2c3037'}}>
                  비밀번호 변경
                </button>
              )}
            </td>
          </tr>
          {/*{isPassword && (*/}
          {/*  <tr>*/}
          {/*    <th>비밀번호 확인</th>*/}
          {/*    <td>*/}
          {/*      <>*/}
          {/*        <ItemInput style={{width: '80%'}} type='password' value={password} onChange={(e) => setPassword(e.target.value)} placeholder='비밀번호를 다시 입력해주세요.' />*/}
          {/*        <button style={{padding: '12px 20px'}} onClick={checkPassword}>*/}
          {/*          비밀번호 확인*/}
          {/*        </button>*/}
          {/*      </>*/}
          {/*    </td>*/}
          {/*  </tr>*/}
          {/*)}*/}
        </ItemTable>
      </ModalDisplay>
    </ModalWrapper>
  )
}

export default React.memo(UserEdit);