import React, { useState } from 'react'
import { Content, Header } from '../Main/styles';
import { CheckTag, CheckTagDiv, ItemInput, ItemTable, ModalButtons, ModalDisplay, ModalHeader, ModalWrapper } from '../../Components/ModalStyles';
import { Checkbox, Select, message } from 'antd';
import { useToken } from 'app/hooks';
import API from 'utils/api';
import { useAuctionSiteList } from 'app/Dosang/hooks/GetAuctionSiteList';
import { useHistory } from 'react-router-dom';
import { PlusOutlined } from '@ant-design/icons';
import { Modal, Upload } from 'antd';
import type { UploadFile, UploadProps } from 'antd';

type FileType = File

const getBase64 = (file: FileType): Promise<string> =>
  new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => resolve(reader.result as string);
    reader.onerror = (error) => reject(error);
  });

export const AuctionSiteAdd = () => {
  const { data: token } = useToken();
  const { mutate } = useAuctionSiteList('');
  const [name, setName] = useState('');
  const [link, setLink] = useState('');
  const [tags, setTags] = useState<string[]>([]);
  const [popular, setPopular] = useState(false);
  const navigate = useHistory();

  const [previewOpen, setPreviewOpen] = useState(false);
  const [previewImage, setPreviewImage] = useState('');
  const [previewTitle, setPreviewTitle] = useState('');
  const [fileList, setFileList] = useState<UploadFile[]>([])
  
  // ** 심볼 이미지 업로드 관련
  const handleCancel = () => setPreviewOpen(false);

  const handlePreview = async (file: UploadFile) => {
    if (!file.url && !file.preview) {
      file.preview = await getBase64(file.originFileObj as FileType);
    }

    setPreviewImage(file.url || (file.preview as string));
    setPreviewOpen(true);
    setPreviewTitle(file.name || file.url!.substring(file.url!.lastIndexOf('/') + 1));
  };

  const handleChange = ({ fileList: newFileList }) => setFileList(newFileList);

  const uploadButton = fileList.length >= 1 ? null : (
    <div>
      <PlusOutlined />
      <div style={{ marginTop: 8 }}>Upload</div>
    </div>
  );

  // ** 태그 선택 (체크박스 방식 사용)
  const handleCheckboxChange = (e, tagName) => {
    if (e.target.checked) {
      setTags([...tags, tagName]);
    } else {
      setTags(tags?.filter(rowId => rowId !== tagName));
    }
  };

  const saveEdit = () => {
    const shouldEdit = window.confirm(`등록하실 내용이 맞습니까?\n사이트: ${name}\n링크: ${link}`);
    if(shouldEdit) {
      if(name == '' && link == '') {
        message.error('등록하실 경매 사이트 정보를 입력해주세요.')
      } else if (name == '') {
        message.error('사이트 명은 필수 입력입니다.')
      } else {
        const targetFilesArray = Array.from(fileList)
				const formData = new FormData()

				targetFilesArray.forEach(file => {
          if (file.originFileObj) {
            formData.append('file', file.originFileObj);
          }
        });

        formData.append('datas', JSON.stringify({
          name: name,
          link: link,
          tags: tags,
          popular: popular
        }));

        API.postImage('POST', '/auction/company/list', token + '', formData)
        .then((res: any) => {
          if (res.result.success) {
            message.success('등록되었습니다');
            navigate.push('/dsadmin/auction_site/list');
            mutate();
          }
        })
        .catch((err: any) => {})
      }
    }
  }

  return (
    <Content style={{position: 'relative'}}>
      <Header>경매 사이트 등록</Header>
      <ModalButtons style={{top: 40, right: 40}}>
        <button className='save' onClick={saveEdit} style={{width: 100}}>저장</button>
      </ModalButtons>
      <ModalWrapper style={{position: 'relative', height: 'auto', marginTop: 40}}>
        <ModalDisplay style={{height: 'auto', zIndex: 1}}>
          <ItemTable style={{marginTop: 0}}>
            <tr>
              <th>이름 *</th>
              <td><ItemInput type='text' value={name} onChange={(e) => setName(e.target.value)} placeholder='경매 사이트 명을 입력해주세요.(필수입력)' /></td>
            </tr>
            <tr>
              <th>링크</th>
              <td><ItemInput type='text' value={link} onChange={(e) => setLink(e.target.value)} placeholder='경매 사이트 링크를 입력해주세요.' /></td>
            </tr>
            <tr>
              <th>태그</th>
              <td>
                <CheckTagDiv>
                  <CheckTag style={{backgroundColor: tags?.includes('메이저') ? '#0d6efd' : 'transparent', color: tags?.includes('메이저') ? '#fff' : '#a0a0a0'}}>
                    <Checkbox id='메이저' checked={tags?.includes('메이저')} key={'메이저'} onChange={(e) => handleCheckboxChange(e, '메이저')} />
                    <label htmlFor='메이저'>메이저</label>
                  </CheckTag>
                  <CheckTag style={{backgroundColor: tags?.includes('프리미엄') ? '#0d6efd' : 'transparent', color: tags?.includes('프리미엄') ? '#fff' : '#a0a0a0'}}>
                    <Checkbox id='프리미엄' checked={tags?.includes('프리미엄')} key={'프리미엄'} onChange={(e) => handleCheckboxChange(e, '프리미엄')} />
                    <label htmlFor='프리미엄'>프리미엄</label>
                  </CheckTag>
                  <CheckTag style={{backgroundColor: tags?.includes('위클리') ? '#0d6efd' : 'transparent', color: tags?.includes('위클리') ? '#fff' : '#a0a0a0'}}>
                    <Checkbox id='위클리' checked={tags?.includes('위클리')} key={'위클리'} onChange={(e) => handleCheckboxChange(e, '위클리')} />
                    <label htmlFor='위클리'>위클리</label>
                  </CheckTag>
                  <CheckTag style={{backgroundColor: tags?.includes('온라인') ? '#0d6efd' : 'transparent', color: tags?.includes('온라인') ? '#fff' : '#a0a0a0'}}>
                    <Checkbox id='온라인' checked={tags?.includes('온라인')} key={'온라인'} onChange={(e) => handleCheckboxChange(e, '온라인')} />
                    <label htmlFor='온라인'>온라인</label>
                  </CheckTag>
                  <CheckTag style={{backgroundColor: tags?.includes('해외') ? '#0d6efd' : 'transparent', color: tags?.includes('해외') ? '#fff' : '#a0a0a0'}}>
                    <Checkbox id='해외' checked={tags?.includes('해외')} key={'해외'} onChange={(e) => handleCheckboxChange(e, '해외')} />
                    <label htmlFor='해외'>해외</label>
                  </CheckTag>
                </CheckTagDiv>
              </td>
            </tr>
            <tr>
              <th>경매 타입 선택 <br />(전체 or 인기 경매 선택)</th>
              <td>
                <CheckTag style={{padding: 0}}>
                  <Checkbox id='인기' checked={popular} onChange={() => setPopular(!popular)} />
                  <label htmlFor='인기' style={{color: popular ? '#101010' : '#a0a0a0'}}>인기 경매</label>
                </CheckTag>
              </td>
            </tr>
            <tr>
              <th>심볼<br />(* 이미지 파일만 업로드 가능)</th>
              <td>
                <>
                  <Upload
                    action="https://run.mocky.io/v3/435e224c-44fb-4773-9faf-380c5e6a2188"
                    listType="picture-card"
                    fileList={fileList}
                    onPreview={handlePreview}
                    onChange={handleChange}
                  >
                    {uploadButton}
                  </Upload>
                  <Modal open={previewOpen} title={previewTitle} footer={null} onCancel={handleCancel}>
                    <img alt="example" style={{ width: '100%' }} src={previewImage} />
                  </Modal>
                </>
              </td>
            </tr>
          </ItemTable>
        </ModalDisplay>
      </ModalWrapper>
    </Content>
  )
}

export default React.memo(AuctionSiteAdd);