import React, { useState } from 'react'
import { Content, Header, Icon, SearchArea } from '../Main/styles';
import { ArtistListTable, ImgPreviewWrapper } from '../Artist/artistStyles';
import API from 'utils/api';
import { useToken } from 'app/hooks';
import { Input, message } from 'antd';
import { useArtworkList } from 'app/Dosang/hooks/GetArtWorkList';
import ArtworkModal from 'app/Dosang/Components/ArtworkModal';
import { IoIosSearch } from "react-icons/io";

export const AuctionArtworkList = () => {
  const { artworkList } = useArtworkList();
  const { data: token } = useToken();
  const [isEdit, setIsEdit] = useState(false);
  const [editItem, setIsEditItem] = useState({});
  const [hoveredImage, setHoveredImage] = useState(null);
  const [search, setSearch] = useState('');
  const filterList = artworkList?.filter((it) => it?.artistName?.toLowerCase().includes(search.toLowerCase()) || it?.title?.toLowerCase().includes(search.toLowerCase()));

  const clickDel = (id, artistName, title) => {
    const shouldDelete = window.confirm(`정말로 '${artistName}'의 '${title}' 을(를) 삭제하시겠습니까?`);

    if(shouldDelete) {
      API.delete('/auction/lot/list/' + id, token + '', {})
      .then((res: any) => {
        if (res.result.success) {
          message.success('삭제되었습니다');
        }
      })
      .catch((err: any) => {})
    }
  }

  const handleMouseOver = (imageUrl) => {
    setHoveredImage(imageUrl);
  }

  const handleMouseOut = () => {
    setHoveredImage(null);
  }

  const columns = [
    {
      dataIndex: 'name',
      title: '경매 사이트',
      render: (value, data) => {
        const company = data?.company;
        return (
          <div className='location' style={{minWidth: '115px'}}>
            <h5>{company}</h5>
          </div>
        )
      }
    },
    {
      dataIndex: 'artistName',
      title: '아티스트',
      render: (value, data) => {
        const artistName = data?.artistName;
        return (
          <div className='name' style={{minWidth: 120}}>
            <h5>{artistName}</h5>
          </div>
        )
      }
    },
    {
      dataIndex: 'title',
      title: '작품명',
      render: (value, data) => {
        const title = data?.title;
        const imageUrl = data?.imageUrl;
        return (
          <div className='name' onMouseOver={() => handleMouseOver(imageUrl)} onMouseOut={handleMouseOut}>
            <h5>{title}</h5>
          </div>
        )
      }
    },
    {
      dataIndex: 'price',
      title: '가격',
      render: (value, data) => {
        const price = parseFloat(data?.price);
        return (
          <div className='name' style={{whiteSpace: 'nowrap'}}>
            <h5 style={{color: '#E27233'}}>₩ {price.toLocaleString()}</h5>
          </div>
        )
      }
    },
    {
      dataIndex: 'link',
      title: '작품 정보',
      render: (value, data) => {
        const link = data?.link;
        return (
          <div className='link'>
            <a href={link} target='_blank'>{link}</a>
          </div>
        )
      }
    },
    {
      dataIndex: 'edit',
      title: '수정/삭제',
      render: (value, data) => {
        const id = data?.id;
        const artistName = data?.artistName;
        const title = data?.title;
        return (
          <div className='edit'>
            <button className='editBtn' onClick={() => {setIsEdit(true); setIsEditItem(data)}}>수정</button>
            <button className='delBtn' onClick={() => clickDel(id, artistName, title)}>삭제</button>
          </div>
        )
      }
    },
  ];

  return (
    <Content>
      <Header>
        낙찰된 작품 목록
        <SearchArea>
          <Input type='text' onChange={(e) => setSearch(e.target.value)} placeholder='작가이름 또는 작품명 검색' />
          <Icon><IoIosSearch /></Icon>
        </SearchArea>
      </Header>
      <ArtistListTable dataSource={filterList} columns={columns} />
      {isEdit && <ArtworkModal setIsEdit={setIsEdit} editItem={editItem} />}
      {hoveredImage && 
        <ImgPreviewWrapper>
          <img src={hoveredImage} alt='hovered_image' />
        </ImgPreviewWrapper>
      }
    </Content>
  )
}

export default React.memo(AuctionArtworkList);