import React, { useState } from 'react'
import { Content, Header, Icon, SearchArea } from '../Main/styles';
import { ArtistListTable } from '../Artist/artistStyles';
import { Input, message } from 'antd';
import API from 'utils/api';
import { useToken } from 'app/hooks';
import ArtistModalEdit from 'app/Dosang/Components/ArtistModalEdit';
import { useUserList } from 'app/Dosang/hooks/GetUserList';
import UserEdit from 'app/Dosang/Components/UserEdit';
import { IoIosSearch } from "react-icons/io";
import {UserSearchHistory} from "../../Components/UserSearchHistory";
import styled from "styled-components";

export const UserList = () => {
  const { data: token } = useToken();
  const { userList, mutate } = useUserList();
  const [isEdit, setIsEdit] = useState(false);
  const [isShowTotalSearch, setIsShowTotalSearch] = useState(false);
  const [editItem, setIsEditItem] = useState({});
  const [search, setSearch] = useState('');
  const filterList = userList?.filter((it) => it?.name?.includes(search));
  const [selectId, setSelectId] = useState(null);

  const clickDel = (id, name) => {
    const shouldDelete = window.confirm(`정말로 '${name}' 를 삭제하시겠습니까?`);
    if(shouldDelete) {
      API.delete('/users/' + id, token + '', {})
      .then((res: any) => {
        if (res.result.success) {
          message.success('삭제되었습니다');
          mutate();
        }
      })
      .catch((err: any) => {})
    }
  }

  const columns = [
    {
      dataIndex: 'id',
      title: 'ID',
      render: (value, data) => {
        const id = data?.id;
        return (
          <div className='id'>
            <h5>{id}</h5>
          </div>
        )
      }
    },
    {
      dataIndex: 'name',
      title: '이름',
      render: (value, data) => {
        const name = data?.name;
        return (
          <div className='location'>
            <h5>{name}</h5>
          </div>
        )
      }
    },
    {
      dataIndex: 'phone',
      title: '핸드폰 번호',
      render: (value, data) => {
        const phone = data?.phone;

        return (
          <div className='location'>
            <h5>{phone}</h5>
          </div>
        )
      }
    },
    {
      dataIndex: 'email',
      title: 'Email',
      render: (value, data) => {
        const email = data?.email;

        return (
          <div className='location'>
            <h5>{email}</h5>
          </div>
        )
      }
    },
    {
      dataIndex: 'roleName',
      title: '닉네임',
      render: (value, data) => {
        const roleName = data?.roleName;
        return (
          <div className='location'>
            <h5>{roleName}</h5>
          </div>
        )
      }
    },
    {
      dataIndex: 'createdAt',
      title: '생성일/마지막 로그인 날짜',
      render: (value, data) => {
        const createdAt = data?.createdAt;
        const loginedAt = data?.loginedAt;
        return (
          <div className='location'>
            <h5>{createdAt}</h5>
            <h5>{loginedAt}</h5>
          </div>
        )
      }
    },
    {
      dataIndex: 'edit',
      title: '수정/삭제',
      render: (value, data) => {
        const id = data?.id;
        const name = data?.name;
        return (
          <div className='edit'>
            <button className='editBtn' onClick={() => {setIsEdit(true); setIsEditItem(data)}}>수정</button>
            <button className='delBtn' onClick={() => clickDel(id, name)}>삭제</button>
          </div>
        )
      }
    },
    {
      dataIndex: 'edit',
      title: '검색기록',
      render: (value, data) => {
        const id = data?.id;
        const name = data?.name;
        return (
          <div className='edit'>
            <button className='editBtn' onClick={() => {
              setSelectId(id);
            }}>상세보기</button>
          </div>
        )
      }
    },
  ];

  return (
    <Content>
      <Header>
        사용자 목록

        <SearchAreaWrapper>
          <SearchArea>
            <Input type='text' onChange={(e) => setSearch(e.target.value)} placeholder='사용자 이름 검색' />
            <Icon><IoIosSearch /></Icon>
          </SearchArea>
          <button className='editBtn' onClick={() => {
            setIsShowTotalSearch(true);
          }}>전체검색기록
          </button>
        </SearchAreaWrapper>
      </Header>
      <ArtistListTable dataSource={filterList} columns={columns}/>
      {isEdit && <UserEdit setIsEdit={setIsEdit} editItem={editItem} />}
      {selectId !== null && <UserSearchHistory selectId={selectId} setSelectId={setSelectId} setIsShowTotalSearch={setIsShowTotalSearch} />}
      {isShowTotalSearch && <UserSearchHistory selectId={null} setSelectId={setSelectId} setIsShowTotalSearch={setIsShowTotalSearch} />}
    </Content>
  )
}

export default React.memo(UserList);

const SearchAreaWrapper = styled.div`
    display: flex;
    gap: 10px;
`