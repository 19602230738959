import React, { useState } from 'react'
import { SectionContainer } from './styles'
import LoginForm from './Forms/LoginForm'
import { ThemeContext } from 'theme/ThemeProvider'

const LoginSection = () => {
	const [loginSuccess, setLoginSuccess] = useState('')
	return (
		<SectionContainer>
			<LoginForm setLoginSuccess={setLoginSuccess} />
		</SectionContainer>
	)
}

export default React.memo(LoginSection)
