import React, { useEffect, useState } from 'react'
import { GlowEffect, HomeWrapper, Page1Display, Page1ImgWrapper, Page2Display, PageImg, PageSubhead, PageTitleArea, PageTitleTxt } from '../styles';
import Page5Img from '../../../assets/page5.png'

const useWindowWidth = () => {
  const [width, setWidth] = useState(window.innerWidth);

  useEffect(() => {
    const handleResize = () => {
      setWidth(window.innerWidth);
    };

    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  return width;
};

export const Page5 = () => {
  const width = useWindowWidth();

  return (
    <HomeWrapper style={{backgroundColor: '#030303', height: 'auto'}}>
      <Page2Display style={{marginLeft: width >= 1400 ? '-200px': width >= 1024 ? -100 : 0, gap: 0, flexDirection: width >= 1024 ? 'row' : 'column'}}>
        {width >= 1024 ? (
          <>
            <Page1ImgWrapper>
              <GlowEffect />
              <PageImg src={Page5Img} />
            </Page1ImgWrapper>
            <PageTitleArea style={{marginLeft: width >= 1400 ? '-400px' : width >= 1200 ? -300 : width >= 1024 ? -200 : 0}}>
              <PageTitleTxt>[ 간편하게 스케줄 확인 ]</PageTitleTxt>
              <PageSubhead style={{width: width >= 1400 ? '391px' : 475}}>
                경매 사이트 별로 진행되고 있는 옥션을 알려주고
                다가오는 경매의 스케줄을 정리.
              </PageSubhead>
            </PageTitleArea>
          </>
        ) : (
          <>
            <PageTitleArea style={{marginLeft: width >= 1400 ? '-400px' : width >= 1200 ? -300 : width >= 1024 ? -200 : 0}}>
              <PageTitleTxt>[ 간편하게 스케줄 확인 ]</PageTitleTxt>
              <PageSubhead style={{width: width >= 1400 ? '391px' : width >= 1000 ? 475 : 380}}>
                경매 사이트 별로 진행되고 있는 옥션을 알려주고
                다가오는 경매의 스케줄을 정리.
              </PageSubhead>
            </PageTitleArea>
            <Page1ImgWrapper>
              <GlowEffect />
              <PageImg src={Page5Img} />
            </Page1ImgWrapper>
          </>
        )}
        
      </Page2Display>
    </HomeWrapper>
  )
}

export default React.memo(Page5);