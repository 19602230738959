import React, { useEffect, useState } from 'react'
import { AppDownBtn, AppDownBtns, GlowEffect, HomeWrapper, Page1ImgWrapper, Page3Display, PageImg, PageSubhead, PageTitleArea, PageTitleTxt } from '../styles';
import Page6Img from '../../../assets/page6.png'
import dosangLogo from '../../../assets/dosangLogo.png'
import { Icon } from '../../Main/styles';
import { FaApple } from 'react-icons/fa6';
import { IoLogoAndroid } from "react-icons/io";

const useWindowWidth = () => {
  const [width, setWidth] = useState(window.innerWidth);

  useEffect(() => {
    const handleResize = () => {
      setWidth(window.innerWidth);
    };

    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  return width;
};

export const Page6 = () => {
  const width = useWindowWidth();

  return (
    <HomeWrapper style={{backgroundColor: '#030303', height: 'auto', marginTop: width >= 1200 ? -200 : -300}}>
      <Page3Display>
        <PageTitleArea style={{gap: '30px', zIndex: 2}}>
          <PageTitleTxt style={{fontSize: '24px', fontWeight: '500'}}>
            지금까지 없었던 미술 경매 정보 어플
          </PageTitleTxt>
          <PageImg src={dosangLogo} width='391px' height='63px' />
        </PageTitleArea>
        <AppDownBtns style={{marginTop: '95px'}}>
          <AppDownBtn onClick={() => window.location.href = 'https://play.google.com/store/apps/details?id=com.ci.dosang&pcampaignid=web_share'}>
            <Icon>
              <IoLogoAndroid />
            </Icon>
            Android 다운로드
          </AppDownBtn>
          <AppDownBtn onClick={() => window.location.href = 'https://apps.apple.com/kr/app/%EB%8F%84%EC%83%81-dosang-%EB%AF%B8%EC%88%A0%ED%92%88-%EA%B2%BD%EB%A7%A4-%EC%95%84%ED%8A%B8-%EC%A0%95%EB%B3%B4/id6502923748'}>
            <Icon>
              <FaApple />
            </Icon>
            IOS 다운로드
          </AppDownBtn>
        </AppDownBtns>
        <Page1ImgWrapper>
          <PageImg src={Page6Img} />
        </Page1ImgWrapper>
      </Page3Display>
    </HomeWrapper>
  )
}

export default React.memo(Page6);