import React, { useEffect, useState } from 'react'
import { GlowEffect, HomeWrapper, Hr, NewsContent, NewsDetail, NewsHeader, NewsInfo, NewsTab, NewsThumbnail, NewsTitle, Page1Display, Page1ImgWrapper, Page2Display, Page2NewsWrapper, PageImg, PageSubhead, PageTitleArea, PageTitleTxt } from '../styles';
import Page2Img from '../../../assets/page2.png'
import News1 from '../../../assets/page2_news1.png'
import News2 from '../../../assets/page2_news2.png'
import { Icon } from '../../Main/styles';
import { BsLightningCharge } from "react-icons/bs";
import { HiOutlineNewspaper } from "react-icons/hi2";

const useWindowWidth = () => {
  const [width, setWidth] = useState(window.innerWidth);

  useEffect(() => {
    const handleResize = () => {
      setWidth(window.innerWidth);
    };

    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  return width;
};

export const Page2 = () => {
  const width = useWindowWidth();

  return (
    <HomeWrapper style={{backgroundColor: '#030303', height: 'auto'}}>
      {width > 650 ? (
        <Page2Display>
        <Page1ImgWrapper>
          <GlowEffect />
          <PageImg className='page2' src={Page2Img} style={{marginLeft: 50}} />
        </Page1ImgWrapper>
        <PageTitleArea style={{marginLeft: '-50px', zIndex: 2}}>
          <PageTitleTxt>[ 실시간 알림과 뉴스 ]</PageTitleTxt>
          <PageSubhead style={{width: '391px'}}>
            미술품의 실시간 응찰, 낙찰 현황을 확인하고
            관련 뉴스까지 한번에 서치 가능.
          </PageSubhead>
          <Page2NewsWrapper>
            <NewsHeader>
              <NewsTab>
                <Icon><BsLightningCharge /></Icon>
                실시간알림
              </NewsTab>
              <NewsTab className='news'>
                <Icon><HiOutlineNewspaper /></Icon>
                NEWS
              </NewsTab>
            </NewsHeader>
            <NewsContent>
              <NewsDetail>
                <NewsTitle>피카소가 그린 여인 초상화 국내 첫 경매 나와...  가치는?</NewsTitle>
                <NewsInfo>연합뉴스TVㅣ1주전</NewsInfo>
              </NewsDetail>
              <NewsThumbnail>
                <img src={News2} />
              </NewsThumbnail>
            </NewsContent>
            <Hr />
            <NewsContent>
              <NewsDetail>
                <NewsTitle>인천공항 미술품 수장고 추진... 착공 전부터 문의 쇄도 ‘후끈’</NewsTitle>
                <NewsInfo>OBSㅣ2주전</NewsInfo>
              </NewsDetail>
              <NewsThumbnail>
                <img src={News1} />
              </NewsThumbnail>
            </NewsContent>
          </Page2NewsWrapper>
        </PageTitleArea>
      </Page2Display>
      ) : (
        <Page2Display style={{flexDirection: 'column', marginLeft: 0}}>
          <div style={{zIndex: 3, display: 'flex', flexDirection: 'column', alignItems: 'center'}}>
            <PageTitleTxt>[ 실시간 알림과 뉴스 ]</PageTitleTxt>
            <PageSubhead style={{width: '391px'}}>
              미술품의 실시간 응찰, 낙찰 현황을 확인하고
              관련 뉴스까지 한번에 서치 가능.
            </PageSubhead>
          </div>
          <PageTitleArea style={{flexDirection: 'row', zIndex: 2, marginLeft: -50}}>
            <Page1ImgWrapper style={{width: '50%'}}>
              <GlowEffect />
              <PageImg className='page2' src={Page2Img} style={{marginLeft: 50}} />
            </Page1ImgWrapper>
            <Page2NewsWrapper style={{zIndex: 2, marginRight: 20}}>
              <NewsHeader>
                <NewsTab>
                  <Icon><BsLightningCharge /></Icon>
                  실시간알림
                </NewsTab>
                <NewsTab className='news'>
                  <Icon><HiOutlineNewspaper /></Icon>
                  NEWS
                </NewsTab>
              </NewsHeader>
              <NewsContent>
                <NewsDetail>
                  <NewsTitle>피카소가 그린 여인 초상화 국내 첫 경매 나와...  가치는?</NewsTitle>
                  <NewsInfo>연합뉴스TVㅣ1주전</NewsInfo>
                </NewsDetail>
                <NewsThumbnail>
                  <img src={News2} />
                </NewsThumbnail>
              </NewsContent>
              <Hr />
              <NewsContent>
                <NewsDetail>
                  <NewsTitle>인천공항 미술품 수장고 추진... 착공 전부터 문의 쇄도 ‘후끈’</NewsTitle>
                  <NewsInfo>OBSㅣ2주전</NewsInfo>
                </NewsDetail>
                <NewsThumbnail>
                  <img src={News1} />
                </NewsThumbnail>
              </NewsContent>
            </Page2NewsWrapper>
          </PageTitleArea>
        </Page2Display>
      )}
    </HomeWrapper>
  )
}

export default React.memo(Page2);