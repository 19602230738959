import React, { useState } from 'react'
import { Content, Header } from '../../Main/styles';
import { ItemInput, ItemTable, ModalButtons, ModalDisplay, ModalHeader, ModalWrapper } from '../../../Components/ModalStyles';
import { message } from 'antd';
import { useToken } from 'app/hooks';
import API from 'utils/api';
import { useHistory } from 'react-router-dom';
import { useBoardList } from 'app/Dosang/hooks/GetBoard';
import TextArea from 'antd/es/input/TextArea';

export const NoticeAdd = () => {
  const { data: token } = useToken();
  const { mutate } = useBoardList('notice');
  const [title, setTitle] = useState('');
  const [content, setContent] = useState('');
  const navigate = useHistory();

  const saveEdit = () => {
    const shouldEdit = window.confirm(`등록하실 내용이 맞습니까?\n제목: ${title}\n내용: ${content}`);
    if(shouldEdit) {
      if(title == '' && content == '') {
        message.error('등록하실 공지 정보를 입력해주세요.')
      } else if (title == '') {
        message.error('제목은 필수 입력입니다.')
      } else {
        API.post('/board/notice', token + '', {
          title: title,
          content: content,
          link: null,
          pubdate: null,
          status: true
        })
        .then((res: any) => {
          if (res.result.success) {
            message.success('등록되었습니다');
            mutate();
            navigate.push('/dsadmin/notice/list');
          }
        })
        .catch((err: any) => {})
      }
    }
  }

  return (
    <Content style={{position: 'relative'}}>
      <Header>공지 등록</Header>
      <ModalButtons style={{top: 40, right: 40}}>
        <button className='save' onClick={saveEdit} style={{width: 100}}>저장</button>
      </ModalButtons>
      <ModalWrapper style={{position: 'relative', height: 'auto', marginTop: 40}}>
        <ModalDisplay style={{height: 'auto', zIndex: 1}}>
          <ItemTable style={{marginTop: 0}}>
            <tr>
              <th>제목 *</th>
              <td><ItemInput type='text' value={title} onChange={(e) => setTitle(e.target.value)} placeholder='제목을 입력해주세요.(필수입력)' /></td>
            </tr>
            <tr>
              <th>내용</th>
              <td>
                <div style={{height: 300}}>
                  <TextArea value={content} onChange={(e) => setContent(e.target.value)} placeholder='내용을 입력해주세요.' style={{minHeight: 280, resize: 'none'}} />
                </div>
              </td>
            </tr>
          </ItemTable>
        </ModalDisplay>
      </ModalWrapper>
    </Content>
  )
}

export default React.memo(NoticeAdd);