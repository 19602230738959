import React, { useState } from 'react'
import { Blacked, ItemInput, ItemTable, ModalButtons, ModalDisplay, ModalHeader, ModalWrapper } from './ModalStyles';
import { message } from 'antd';
import { useToken } from 'app/hooks';
import API from 'utils/api';
import TextArea from 'antd/es/input/TextArea';
import { useBoardList } from '../hooks/GetBoard';

export const InquireEdit = ({ setIsEdit, editItem }) => {
  const { data: token } = useToken();
  const { mutate } = useBoardList('inquire');
  const [answer, setAnswer] = useState(editItem?.answer);
  const [edit, setEdit] = useState(false)

  const saveEdit = () => {
    const shouldEdit = window.confirm(`답변남길 내용이 맞습니까?\n내용: ${answer}`);
    if (answer == '') {
      message.error('답변을 입력하지 않았습니다.');
    } else {
      if(shouldEdit) {
        API.post('/board/inquire/' + editItem?.id + '/answer', token + '', {
          content: answer,
        })
        .then((res: any) => {
          if (res.result && res.result.success) {
            message.success('등록되었습니다');
            mutate();
            setIsEdit(false);
          } 
        })
        .catch((err: any) => {
          if (err.code === 403) {
            message.error('접근 권한 오류입니다.');
          }
        })
        
      }
    }
  }

  const clickDel = (id) => {
    const shouldDelete = window.confirm(`정말로 답변을 삭제하시겠습니까?`);

    if(shouldDelete) {
      API.delete('/board/inquire/' + id + '/answer', token + '', {})
      .then((res: any) => {
        if (res.result.success) {
          message.success('삭제되었습니다');
          mutate();
        }
      })
      .catch((err: any) => {})
    }
  }


  return (
    <ModalWrapper>
      <Blacked />
      <ModalDisplay style={{width: 1000, height: 'auto'}}>
        <ModalHeader>
          문의 내용
          <ModalButtons>
            <button className='cancel' onClick={() => setIsEdit(false)}>취소</button>
            <button className='save' onClick={saveEdit}>저장</button>
          </ModalButtons>
        </ModalHeader>
        <ItemTable>
          <tr>
            <th>제목</th>
            <td>{editItem?.title}</td>
          </tr>
          <tr>
            <th>내용</th>
            <td>{editItem?.content}</td>
          </tr>
        </ItemTable>
        <ItemTable>
          <tr>
            <th>답변</th>
            <td>
              {editItem?.answer == '' || editItem?.answer == null || edit ? (
                <div style={{height: 310, display: 'flex', flexDirection: 'column', justifyContent: 'flex-start', gap: 20}}>
                  <TextArea value={answer} onChange={(e) => setAnswer(e.target.value)} placeholder='답변을 입력해주세요.'  style={{minHeight: 250, resize: 'none'}} />
                  {edit && <button className='cancel' onClick={() => setEdit(false)} style={{width: '100%', marginLeft: -0}}>취소</button>}
                </div>
              ) : (
                <div>
                  {editItem?.answer}
                  <button className='edit' onClick={() => setEdit(true)} style={{padding: '6px 12px', border: '1px solid #4c4cf8', backgroundColor: 'transparent', color: '#4c4cf8'}}>
                    수정
                  </button>
                  <button className='delete' onClick={() => clickDel(editItem?.id)} style={{padding: '6px 12px', marginLeft: 6, backgroundColor: '#f53b3b', border: '1px solid #f53b3b'}}>
                    삭제
                  </button>
                </div>
              )}
            </td>
          </tr>
        </ItemTable>
      </ModalDisplay>
    </ModalWrapper>
  )
}

export default React.memo(InquireEdit);