import useSWR from 'swr'
import API from '../../../utils/api'
import { getPersistData } from '../../hooks/persist'
import { useToken } from 'app/hooks'

const KEY = '/user/history/search' // (1) 전체 아티스트 목록 조회

let swrData: any = getPersistData(KEY)


export function useUserSearchHistory(userId) {
	const { data: token } = useToken()
	const url = KEY;

	const { data, mutate } = useSWR<any>(
		url+ `${userId ? '?userId=' + userId : ''}`,
		async () => {
			const response = await API.get(url+ `${userId ? '?userId=' + userId : ''}`, token + '', {})
			swrData = response.result?.result

			return swrData
		},
		{ revalidateOnFocus: true }
	)

	return {
		userSearchHistory: data?.list,
		total:data?.totalCount,
		mutate: (value?: any) => {
			if (value != undefined) {
				swrData = value
			}
			return mutate()
		},
	}
}