import React, { useEffect, useState } from 'react'
import { GlowEffect, HomeWrapper, Page1Display, Page1ImgWrapper, Page4ImgWrapper, PageImg, PageSubhead, PageTitleArea, PageTitleTxt } from '../styles';
import Page4_1Img from '../../../assets/page4_1.png'
import Page4_2Img from '../../../assets/page4_2.png'
import Light from '../../../assets/Light.png'

const useWindowWidth = () => {
  const [width, setWidth] = useState(window.innerWidth);

  useEffect(() => {
    const handleResize = () => {
      setWidth(window.innerWidth);
    };

    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  return width;
};


export const Page4 = () => {
  const width = useWindowWidth();

  return (
    <HomeWrapper style={{backgroundColor: '#030303', height: 'auto', marginTop: width>= 1200 ? 0 : -330}}>
      <Page1Display>
        <PageTitleArea style={{zIndex: 2}}>
          <PageTitleTxt>[ 실시간 낙찰가 업데이트 ]</PageTitleTxt>
          <PageSubhead style={{marginBottom: 100}}>
            미술품의 낙찰가를 모아 한번에 확인하고<br />
            작가검색으로 원하는 작가의 낙찰가만 모아서 보기.
          </PageSubhead>
        </PageTitleArea>
        <Page4ImgWrapper>
          <Page1ImgWrapper>
            <PageImg src={Page4_1Img} />
          </Page1ImgWrapper>
          <Page1ImgWrapper style={{position: width >= 1200 ? 'absolute' : 'relative', top: width >= 1200 ? 110 : 0, right: width >= 1200 ? -310 : 0}}>
            <PageImg src={Page4_2Img} />
          </Page1ImgWrapper>
          <Page1ImgWrapper style={{position: 'absolute', bottom: 0, left: width >= 1200 ? -500 : -300, width: '1408px'}}>
            <PageImg src={Light} />
          </Page1ImgWrapper>
        </Page4ImgWrapper>
      </Page1Display>
    </HomeWrapper>
  )
}

export default React.memo(Page4);