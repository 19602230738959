import React, { useState } from 'react'
import { Content, Header } from '../Main/styles';
import { ItemInput, ItemTable, ModalButtons, ModalDisplay, ModalHeader, ModalWrapper } from '../../Components/ModalStyles';
import { message } from 'antd';
import { useToken } from 'app/hooks';
import API from 'utils/api';
import { useUserList } from 'app/Dosang/hooks/GetUserList';
import { useHistory } from 'react-router-dom';

export const UserAdd = () => {
  const { data: token } = useToken();
  const { mutate } = useUserList();
  const [email, setEmail] = useState('');
  const [name, setName] = useState('');
  const [password, setPassword] = useState('');
  const [passwordCheck, setPasswordCheck] = useState('');
  const [phone, setPhone] = useState('');
  const navigate = useHistory();

  const saveEdit = () => {
    const shouldEdit = window.confirm(`등록하실 내용이 맞습니까?\n Email: ${email}\n이름: ${name}\n핸드폰 번호: ${phone}`);
    if(shouldEdit) {
      if(email == '' && name == '' && password == '' && phone == '') {
        message.error('등록하실 사용자 정보를 입력해주세요.')
      } else if (name == '') {
        message.error('이름은 필수 입력입니다.')
      } else if (password == '') {
        message.error('비밀번호를 입력해주세요.')
      } else if (email == '') {
        message.error('이메일은 필수 입력입니다.')
      } else if (passwordCheck == '') {
        message.error('비밀번호 확인을 해주세요.')
      } else if (passwordCheck !== password) {
        message.error('비밀번호와 확인하신 비밀번호가 맞지 않습니다.')
      } else {
        API.post('/users', token + '', {
          email: email,
          password: password,
          name: name,
          phone: phone,
          role: 1
        })
        .then((res: any) => {
          if (res.result.success) {
            message.success('등록되었습니다');
            navigate.push('/dsadmin/user/list');
            mutate();
          }
        })
        .catch((err: any) => {})
      }
    }
  }

  const checkPassword = () => {
    if(password != '') {
      if(passwordCheck === password) {
        message.success('비밀번호가 확인되었습니다.');
      } else {
        message.error('비밀번호가 일치하지 않습니다.');
      }
    } else {
      message.error('비밀번호를 입력해주세요.');
    }
  }

  return (
    <Content style={{position: 'relative'}}>
      <Header>사용자 수동 등록</Header>
      <ModalButtons style={{top: 40, right: 40}}>
        <button className='save' onClick={saveEdit} style={{width: 100}}>저장</button>
      </ModalButtons>
      <ModalWrapper style={{position: 'relative', height: 'auto', marginTop: 40}}>
        <ModalDisplay style={{height: 'auto', zIndex: 1}}>
          <ItemTable style={{marginTop: 0}}>
            <tr>
              <th>Email *</th>
              <td><ItemInput type='text' value={email} onChange={(e) => setEmail(e.target.value)} placeholder='사용자 이메일을 입력해주세요.(필수입력)' /></td>
            </tr>
            <tr>
              <th>이름 *</th>
              <td><ItemInput type='text' value={name} onChange={(e) => setName(e.target.value)} placeholder='사용자 이름을 입력해주세요.(필수입력)' /></td>
            </tr>
            <tr>
              <th>비밀번호 *</th>
              <td><ItemInput type='password' value={password} onChange={(e) => setPassword(e.target.value)} placeholder='사용자 비밀번호를 입력해주세요.(필수입력)' /></td>
            </tr>
            <tr>
              <th>비밀번호 확인 *</th>
              <td>
                <ItemInput style={{width: '80%'}} type='password' value={passwordCheck} onChange={(e) => setPasswordCheck(e.target.value)} placeholder='사용자 비밀번호를 한번 더 입력해주세요.' />
                <button onClick={checkPassword}>확인</button>
              </td>
            </tr>
            <tr>
              <th>핸드폰 번호</th>
              <td><ItemInput type='text' value={phone} onChange={(e) => setPhone(e.target.value)} placeholder='사용자 핸드폰 번호를 입력해주세요.' /></td>
            </tr>
          </ItemTable>
        </ModalDisplay>
      </ModalWrapper>
    </Content>
  )
}

export default React.memo(UserAdd);