import React, { useEffect, useRef, useState } from 'react'
import { Logo, Menu, MenuDisplay, MenuWrapper, SideBarWrapper, SubHeader, SubMenuWrapper } from './styles';
import logo from '../../assets/logo.png'
import dosangLogo from '../../assets/dosangLogo.png'
import { Icon } from '../Main/styles';
import { FaCaretDown, FaCaretRight, FaCaretUp } from 'react-icons/fa6';
import { useHistory } from 'react-router-dom';
import {Button} from "antd";

export const SideBar = ({ path2, path3 }) => {
  const navigate = useHistory();
  const [isClick, setIsClick] = useState('');
  const ref = useRef<HTMLDivElement | null>(null);

  const clickSubMenu = (menu) => {
    navigate.push(menu);
    setIsClick('');
  }

  useEffect(() => {
    function handleClickOutside(event) {
      if (ref.current && !ref.current.contains(event.target)) {
        setIsClick('');
      }
    }

    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  return (
    <SideBarWrapper>
      <Logo>
        <img className='logo' src={logo} />
        <img className='txtLogo' src={dosangLogo} />
      </Logo>
      <MenuWrapper ref={ref}>
        <Menu style={{backgroundColor: isClick == 'users' ? '#2c3037': 'transparent', color: isClick == 'users' ? '#fff': '#2c3037', padding: 0}}>
          <MenuDisplay onClick={() => setIsClick('users')}>
            Users 
            <Icon><FaCaretRight /></Icon>
          </MenuDisplay> 
          <SubMenuWrapper style={{display: isClick == 'users' ? 'block' : 'none'}}>
            <Menu onClick={() => clickSubMenu('/dsadmin/user/list')} style={{backgroundColor: path2+path3 == 'userlist' ? '#2c3037': 'transparent', color: path2+path3 == 'userlist' ? '#fff': '#2c3037'}}>
              사용자 목록
            </Menu>
            <Menu onClick={() => clickSubMenu('/dsadmin/user/add')} style={{backgroundColor: path2+path3 == 'useradd' ? '#2c3037': 'transparent', color: path2+path3 == 'useradd' ? '#fff': '#2c3037'}}>
              사용자 등록
            </Menu>
          </SubMenuWrapper>
        </Menu>
        <Menu style={{backgroundColor: isClick == 'artist' ? '#2c3037': 'transparent', color: isClick == 'artist' ? '#fff': '#2c3037', padding: 0}}>
          <MenuDisplay onClick={() => setIsClick('artist')}>
            Artist 
            <Icon><FaCaretRight /></Icon>
          </MenuDisplay> 
          <SubMenuWrapper style={{display: isClick == 'artist' ? 'block' : 'none'}}>
            <Menu onClick={() => clickSubMenu('/dsadmin/artist/list')} style={{backgroundColor: path2+path3 == 'artistlist' ? '#2c3037': 'transparent', color: path2+path3 == 'artistlist' ? '#fff': '#2c3037'}}>
              아티스트 목록
            </Menu>
            <Menu onClick={() => clickSubMenu('/dsadmin/artist/add')} style={{backgroundColor: path2+path3 == 'artistadd' ? '#2c3037': 'transparent', color: path2+path3 == 'artistadd' ? '#fff': '#2c3037'}}>
              아티스트 수동 등록
            </Menu>
            <Menu onClick={() => clickSubMenu('/dsadmin/artist/rank')} style={{backgroundColor: path2+path3 == 'artistrank' ? '#2c3037': 'transparent', color: path2+path3 == 'artistrank' ? '#fff': '#2c3037'}}>
              실시간 순위
            </Menu>
            <Menu onClick={() => clickSubMenu('/dsadmin/artist/worldRank')} style={{backgroundColor: path2+path3 == 'artistworldRank' ? '#2c3037': 'transparent', color: path2+path3 == 'artistworldRank' ? '#fff': '#2c3037'}}>
              아티스트 세계순위
            </Menu>
          </SubMenuWrapper>
        </Menu>
        <Menu style={{backgroundColor: isClick == 'auction' ? '#2c3037': 'transparent', color: isClick == 'auction' ? '#fff': '#2c3037', padding: 0}}>
          <MenuDisplay onClick={() => setIsClick('auction')}>
            Auction 
            <Icon><FaCaretRight /></Icon>
          </MenuDisplay> 
          <SubMenuWrapper style={{display: isClick == 'auction' ? 'block' : 'none'}}>
            <SubHeader>경매</SubHeader>
            <Menu onClick={() => clickSubMenu('/dsadmin/auction/list')} style={{backgroundColor: path2+path3 == 'auctionlist' ? '#2c3037': 'transparent', color: path2+path3 == 'auctionlist' ? '#fff': '#2c3037'}}>
              경매일정
            </Menu>
            <Menu onClick={() => clickSubMenu('/dsadmin/auction/add')} style={{backgroundColor: path2+path3 == 'auctionadd' ? '#2c3037': 'transparent', color: path2+path3 == 'auctionadd' ? '#fff': '#2c3037', borderBottom: 'none'}}>
              경매 수동 등록
            </Menu>
            <SubHeader>경매 사이트</SubHeader>
            <Menu onClick={() => clickSubMenu('/dsadmin/auction_site/list')} style={{backgroundColor: path2+path3 == 'auction_sitelist' ? '#2c3037': 'transparent', color: path2+path3 == 'auction_sitelist' ? '#fff': '#2c3037'}}>
              경매 사이트 목록
            </Menu>
            <Menu onClick={() => clickSubMenu('/dsadmin/auction_site/add')} style={{backgroundColor: path2+path3 == 'auction_siteadd' ? '#2c3037': 'transparent', color: path2+path3 == 'auction_siteadd' ? '#fff': '#2c3037', borderBottom: 'none'}}>
              경매 사이트 등록
            </Menu>
            <SubHeader>작품</SubHeader>
            <Menu onClick={() => clickSubMenu('/dsadmin/auction_artwork/list')} style={{backgroundColor: path2+path3 == 'auction_artworklist' ? '#2c3037': 'transparent', color: path2+path3 == 'auction_artworklist' ? '#fff': '#2c3037'}}>
              낙찰된 작품 목록
            </Menu>
            <Menu onClick={() => clickSubMenu('/dsadmin/auction_artwork/add')} style={{backgroundColor: path2+path3 == 'auction_artworkadd' ? '#2c3037': 'transparent', color: path2+path3 == 'auction_artworkadd' ? '#fff': '#2c3037', borderBottom: 'none'}}>
              작품 수동 등록
            </Menu>
          </SubMenuWrapper>
        </Menu>
        <Menu style={{backgroundColor: isClick == 'board' ? '#2c3037': 'transparent', color: isClick == 'board' ? '#fff': '#2c3037', padding: 0}}>
          <MenuDisplay onClick={() => setIsClick('board')}>
            Board 
            <Icon><FaCaretRight /></Icon>
          </MenuDisplay> 
          <SubMenuWrapper style={{display: isClick == 'board' ? 'block' : 'none'}}>
            <SubHeader>공지</SubHeader>
            <Menu onClick={() => clickSubMenu('/dsadmin/notice/list')} style={{backgroundColor: path2+path3 == 'noticelist' ? '#2c3037': 'transparent', color: path2+path3 == 'noticelist' ? '#fff': '#2c3037'}}>
              공지 목록
            </Menu>
            <Menu onClick={() => clickSubMenu('/dsadmin/notice/add')} style={{backgroundColor: path2+path3 == 'noticeadd' ? '#2c3037': 'transparent', color: path2+path3 == 'noticeadd' ? '#fff': '#2c3037', borderBottom: 'none'}}>
              공지 등록
            </Menu>
            <SubHeader>문의</SubHeader>
            <Menu onClick={() => clickSubMenu('/dsadmin/inquire/list')} style={{backgroundColor: path2+path3 == 'inquirelist' ? '#2c3037': 'transparent', color: path2+path3 == 'inquirelist' ? '#fff': '#2c3037'}}>
              문의 목록
            </Menu>
            <SubHeader>뉴스</SubHeader>
            <Menu onClick={() => clickSubMenu('/dsadmin/news/list')} style={{backgroundColor: path2+path3 == 'newslist' ? '#2c3037': 'transparent', color: path2+path3 == 'newslist' ? '#fff': '#2c3037'}}>
              뉴스 목록
            </Menu>
          </SubMenuWrapper>
        </Menu>
      </MenuWrapper>

      <Button
        style={{backgroundColor: '#ed1a1a', color: 'white', fontWeight: 'bold', position: 'absolute', bottom: 30, width: '100%'}}
        onClick={() => navigate.push('/dsadmin/logout')}
      >Logout</Button>
    </SideBarWrapper>
  )
}

export default React.memo(SideBar);