import React from 'react'
import { Switch, Route, BrowserRouter, Redirect } from 'react-router-dom'
import * as PAGE from './pagesImport'
import { GlobalStyle } from 'styles/global-styles'
import { NotFoundPage } from './components/NotFoundPage/Loadable'
import { useToken } from './hooks'
import { LogoutPage } from './pages/AuthenticationPages/LogoutPage/index'
import { Dosang } from './Dosang/organism/Main';

export function Routes() {
	const { data: token } = useToken()

	return (
		<BrowserRouter>
			<Switch>
				<Route exact path="/" component={Dosang} />
				<Route path="/dsadmin">
					<Route
						exact
						path="/dsadmin/login"
						component={token ? Dosang : PAGE.LoginPage}
					/>
					<Route exact path="/dsadmin/logout" component={LogoutPage} />
					<Route  path="/dsadmin*" component={token ? Dosang : PAGE.LoginPage} />
				</Route>
				<Route path="*" component={NotFoundPage} />
			</Switch>
			<GlobalStyle />
		</BrowserRouter>
	)
}
