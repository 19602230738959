import React, { useState } from 'react'
import { DosangDisplay, DosangWrapper, WelcomeTxt, WelcomeWrapper } from './styles';
import SideBar from '../SideBar';
import UserList from '../User/UserList';
import UserAdd from '../User/UserAdd';
import ArtistList from '../Artist/ArtistList';
import ArtistAdd from '../Artist/ArtistAdd';
import ArtistRank from '../Artist/ArtistRank';
import ArtistWorldRank from '../Artist/ArtistWorldRank';
import AuctionList from '../Auction/AuctionList';
import AuctionAdd from '../Auction/AuctionAdd';
import AuctionSiteList from '../Auction/AuctionSiteList';
import AuctionSiteAdd from '../Auction/AuctionSiteAdd';
import AuctionArtworkList from '../Auction/AuctionArtworkList';
import AuctionArtworkAdd from '../Auction/AuctionArtworkAdd';
import Home from '../Home';
import dosangLogo from '../../assets/dosangLogo.png'
import NoticeList from '../Board/Notice/NoticeList';
import NoticeAdd from '../Board/Notice/NoticeAdd';
import InquireList from '../Board/Inquire/InquireList';
import {NewsList} from "../Board/News/NewsList";
import {useHistory} from "react-router-dom";

export const Dosang = () => {

  const currentPath = window.location.pathname;
  const path1 = currentPath.split('/')[1];
  const path2 = currentPath.split('/')[2];
  const path3 = currentPath.split('/')[3];
  console.log(path2)

  return (
    <>
    {path1 == 'dsadmin' ? (
      <DosangWrapper>
        <DosangDisplay>
          <SideBar path2={path2} path3={path3} />
          {path2 == '' && (
            <WelcomeWrapper>
              <img src={dosangLogo} />
              <WelcomeTxt>안녕하세요 : )</WelcomeTxt>
              <WelcomeTxt>도상 관리자 페이지입니다</WelcomeTxt>
            </WelcomeWrapper>
          )}
          {path2+path3 == 'userlist' && <UserList />}
          {path2+path3 == 'useradd' && <UserAdd />}
          {path2+path3 == 'artistlist' && <ArtistList />}
          {path2+path3 == 'artistadd' && <ArtistAdd />}
          {path2+path3 == 'artistrank' && <ArtistRank />}
          {path2+path3 == 'artistworldRank' && <ArtistWorldRank />}
          {path2+path3 == 'auctionlist' && <AuctionList />}
          {path2+path3 == 'auctionadd' && <AuctionAdd />}
          {path2+path3 == 'auction_sitelist' && <AuctionSiteList />}
          {path2+path3 == 'auction_siteadd' && <AuctionSiteAdd />}
          {path2+path3 == 'auction_artworklist' && <AuctionArtworkList />}
          {path2+path3 == 'auction_artworkadd' && <AuctionArtworkAdd />}
          {path2+path3 == 'noticelist' && <NoticeList />}
          {path2+path3 == 'noticeadd' && <NoticeAdd />}
          {path2+path3 == 'inquirelist' && <InquireList />}
          {path2+path3 == 'newslist' && <NewsList />}
        </DosangDisplay>
      </DosangWrapper>
    ) : <Home />}
    </>
  )
}

export default React.memo(Dosang);